import { useState, useEffect, useRef, useCallback } from "react";

export function useFetchApi(promiseRequest, requestParameters) {
  //Return the promise result or the errors associated with it
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const componentIsMounted = useRef(true);

  const execRequest = useCallback(
    async (newParameters = null) => {
      try {
        setIsLoading(true);
        const response = await promiseRequest(
          newParameters ?? requestParameters
        );
        if (componentIsMounted.current) {
          setIsLoading(false);
          setHasError(false);

          setData(response.data);
          setMessage(response.message);
          setStatus(response.status);
        }
      } catch (error) {
        if (componentIsMounted.current) {
          setHasError(true);
          setIsLoading(false);

          if (error.error) {
            // Request made and server responded
            setError(error.error);
            setData(error.data);
            setStatus(error.status);

            setMessage(error.message);
          } else if (error.request) {
            // The request was made but no response was received
            setError(error);
            setData(error.request);
          } else {
            // Something happened when setting up the request
            throw new Error("Unrecognized error: ", error.message ?? error);
          }
        }
      }
    },
    [requestParameters, promiseRequest]
  );

  useEffect(() => {
    execRequest(requestParameters);
    return () => {
      setIsLoading(false);
      componentIsMounted.current = false;
    };
  }, [execRequest, requestParameters]);

  return {
    status,
    message,
    isLoading,
    data,
    hasError,
    error,
    updateData: execRequest,
  };
}
