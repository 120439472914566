import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Form, Formik } from "formik";
import * as yup from "yup";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import { login } from "services/api/Auth";
import { AuthContext } from "context";
import { FormikCheckbox, FormikTextField } from "components/FormFields";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(componentStyles);

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a proper email")
    .required("Email is required to login"),
  password: yup
    .string()
    .required("Password is required to login")
    .min(6, "Password must be at least 6 characters"),
  remember_me: yup.boolean().nullable(),
});

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const { loadUser } = useContext(AuthContext);

  const handleLogin = async (values) => {
    const { email, password, remember_me } = values;
    try {
      const loginResponse = await login(email, password, remember_me);
      if (!loginResponse.error) {
        loadUser(loginResponse.data.access_token);
      }
    } catch (error) {
      enqueueSnackbar(
        error?.data?.message ?? "Error making login: " + error?.message ?? "",
        { variant: "error" }
      );
    }
  };

  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              marginBottom="1rem"
              fontSize="1rem"
            >
              <Box fontSize="90%" fontWeight="400" component="small">
                Sign In
              </Box>
            </Box>
            <Formik
              validateOnChange={true}
              initialValues={{
                email: "",
                password: "",
                remember_me: false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              {({ isSubmitting }) => (
                <Form noValidate autoComplete="off">
                  <FormControl
                    variant="outlined"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FormikTextField
                      autoComplete="off"
                      type="email"
                      placeholder="Email"
                      name="email"
                      adormentComponent={Email}
                    />
                  </FormControl>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FormikTextField
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      name="password"
                      adormentComponent={Lock}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <FormikCheckbox
                        type="checkbox"
                        name="remember_me"
                        color="primary"
                      />
                    }
                    label="Remeber me"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlLabelRoot,
                      label: classes.formControlLabelLabel,
                    }}
                  />
                  <Box textAlign="center" marginY="1.5rem">
                    <Button type="submit" color="primary" variant="contained">
                      {!!isSubmitting ? (
                        <CircularProgress color="inherit" size={15} />
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
        {/* <Grid container component={Box} marginTop="1rem"> TODO implement forget password flow
          <Grid item xs={6} component={Box} textAlign="left">
            <a
              href="#admui"
              onClick={(e) => e.preventDefault()}
              className={classes.footerLinks}
            >
              Forgot password
            </a>
          </Grid>
          <Grid item xs={6} component={Box} textAlign="right">
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

export default Login;
