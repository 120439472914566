import { axios } from "services/api/Config";
import { wrapPromise } from "../Contracts/loadContract";

export const getAnnouncementsForStudents = () => {
  return axios.get("announcements/students");
};

export function announcements() {
  let getAnnouncementsPromise = getAnnouncementsForStudents();
  return {
    studentsAnnouncements: wrapPromise(getAnnouncementsPromise),
  };
}
