import { useState, useCallback, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { setAuthToken } from "services/api/Config";
import { getStudentInfo } from "services/api/Repos/Student";

let logoutTimer;

export function useAuth() {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [student_id, setStudentId] = useState(false);
  const [location_id, setLocationId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [studentInfo, setStudentInfo] = useState({
    id: "",
    location_id: "",
    first_name: "N/A",
    last_name: "N/A",
    middle_name: "N/A",
    email: "N/A",
    file_number: "N/A",
    photo_url: "",
    gender: "N/A",
    birth_date: "N/A",
    type: "N/A",
    location: "N/A",
    is_booked_by_agent: false,
    read_orientation: false,
    nationality: "N/A",
    county_of_birth: "N/A",
    city_of_birth: "N/A",
    us_city: "N/A",
    us_state: "N/A",
    us_address: "N/A",
    us_zip_code: "N/A",
    us_phone_number: "N/A",
    us_second_phone_number: "N/A",
    f_city: "N/A",
    f_state: "N/A",
    f_address: "N/A",
    f_country: "N/A",
    f_zip_code: "N/A",
    f_phone_number: "N/A",
    f_second_phone_number: "N/A",
  });

  const loadUser = useCallback((token) => {
    setIsLoading(true);
    var tokenJwt = jwt_decode(token);
    setAuthToken(token);
    setStudentId(tokenJwt.student_id);
    setLocationId(tokenJwt.location_id);
    const tokenExpirationDate = new Date(tokenJwt.exp * 1000);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      "language-systems-user-data",
      JSON.stringify({
        student_id: tokenJwt.student_id,
        location_id: tokenJwt.location_id,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
    setToken(token);
    getStudentInfo()
      .then((studentResponse) => {
        setIsLoading(false);
        if (!studentResponse.error) {
          setStudentInfo(studentResponse.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  const logout = useCallback(() => {
    setIsLoading(true);
    setToken(null);
    setTokenExpirationDate(null);
    setStudentId(null);
    setLocationId(null);
    localStorage.removeItem("language-systems-user-data");
    setAuthToken();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem("language-systems-user-data")
    );
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      loadUser(storedData.token);
    } else {
      setIsLoading(false);
    }
  }, [loadUser]);

  return {
    token,
    loadUser,
    logout,
    student_id,
    location_id,
    studentInfo,
    isLoading,
    setStudentInfo,
  };
}
