import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import SchoolIcon from "@material-ui/icons/School";

// core components
import CardStats from "components/Cards/CardStats.js";
import CalendarLabels from "components/Cards/CalendarLabels";
import { printDateFriendly } from "helpers/date";

import componentStyles from "assets/theme/components/calendar-header.js";
import { useLatestClass } from "hooks";

const useStyles = makeStyles(componentStyles);

const CalendarHeader = () => {
  const classes = useStyles();
  const latestClass = useLatestClass();

  return (
    <div className={classes.header}>
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.containerRoot }}
      >
        <div>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <CardStats
                subtitle="Current Class"
                title={latestClass?.program.program_name ?? "Not found"}
                icon={SchoolIcon}
                color={"bgSuccess"}
                footer={
                  <Box component="span" whiteSpace="nowrap">
                    Level: {latestClass?.level.level_name ?? "N/A"}
                    <br></br>
                    Assigned at: {printDateFriendly(latestClass?.assign_date)}
                    <br></br>
                    Attendance:{" "}
                    {latestClass?.attendance.attendance_percent ?? "N/A"}%
                  </Box>
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <CalendarLabels icon={SchoolIcon} color={"bgWarningLight"} />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default CalendarHeader;
