import {
  Button,
  DialogContentText,
  FormControl,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import { FormikTextField } from "components/FormFields";
import { AuthContext } from "context";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { updateStudentInfo, updatePassword } from "services/api/Repos/Student";
import * as yup from "yup";

const validationSchema = yup.object({
  us_phone_number: yup
    .string()
    .max(50, "Enter a valid phone number")
    .min(9, "Enter a valid phone number"),
  us_second_phone_number: yup
    .string()
    .max(50, "Enter a valid phone number")
    .min(9, "Enter a valid phone number"),
});

const validationSchemaPassword = yup.object({
  old_password: yup.string().required("Current password is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password needs to have at least 6 characters"),
  password_confirmation: yup
    .string()
    .required("Passwords confirmation is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

function UpdateProfileInfo() {
  const { enqueueSnackbar } = useSnackbar();
  const { studentInfo, setStudentInfo } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditUserInfo = async (values) => {
    try {
      setIsLoading(true);
      const { data, message } = await updateStudentInfo(values);
      setStudentInfo(data);
      enqueueSnackbar(
        message ? message : "User information edited successfully",
        { variant: "success" }
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(
        error?.data?.message ? error?.data?.message : "Error updating info",
        { variant: "error" }
      );
    }
  };

  const handleUpdatePassowrd = async (values) => {
    try {
      setIsLoading(true);
      const { message } = await updatePassword(values);
      setIsLoading(false);
      enqueueSnackbar(message ? message : "Password updated successfully", {
        variant: "success",
      });
    } catch (error) {
      setIsLoading(false);
      if (error.status === 422) {
        for (var key of Object.keys(values)) {
          if (error?.data?.errors[key] ?? false) {
            enqueueSnackbar(error.data.errors[key], { variant: "error" });
            return;
          }
        }
        enqueueSnackbar(error?.data?.message ?? "Error updating password", {
          variant: "error",
        });
        return;
      }
      enqueueSnackbar(
        error?.data?.message ? error?.data?.message : "Error updating password",
        { variant: "error" }
      );
    }
  };
  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          old_password: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={validationSchemaPassword}
        onSubmit={handleUpdatePassowrd}
      >
        {({ isSubmitting, isValid, touched }) => (
          <Form noValidate autoComplete="off">
            <Typography variant="h3">Change Password</Typography>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FormikTextField
                autoComplete="off"
                type="password"
                label="Current Password"
                name="old_password"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FormikTextField
                autoComplete="off"
                type="password"
                label="New Password"
                name="password"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FormikTextField
                autoComplete="off"
                type="password"
                label="Confirm new password"
                name="password_confirmation"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || !!isSubmitting || !touched}
            >
              {!!isSubmitting ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                "Change Password"
              )}
            </Button>
          </Form>
        )}
      </Formik>
      <Formik
        validateOnChange={true}
        initialValues={{
          us_phone_number: studentInfo?.us_phone_number ?? "",
          us_second_phone_number: studentInfo?.us_second_phone_number ?? "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleEditUserInfo}
      >
        {({ isSubmitting, isValid, touched }) => (
          <Form noValidate autoComplete="off" style={{ marginTop: "4rem" }}>
            <DialogContentText>Edit your contact information</DialogContentText>
            <FormControl
              variant="outlined"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FormikTextField
                autoComplete="off"
                type="email"
                label="Phone number"
                name="us_phone_number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FormikTextField
                autoComplete="off"
                label="Second Phone Number"
                name="us_second_phone_number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || !!isSubmitting || !touched}
            >
              {!!isSubmitting ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UpdateProfileInfo;
