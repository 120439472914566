import {
  Paper,
  Box,
  Hidden,
  Typography,
  Grid,
  CircularProgress,
  useTheme,
} from "@material-ui/core";

import { useFetchApi } from "hooks";
import React from "react";
import { getDocumentsList } from "services/api/Repos/Documents";
import Document from "./Document";
import UploadedFiles from "./UploadedFiles";

const documentsTypes = {
  application_form: {
    title: "Application Form",
    file_type: "application",
  },
  passport_copy: {
    title: "Passport Copy",
    file_type: "passport",
  },
  bank_statement: {
    title: "Bank Statement",
    file_type: "bank_statement",
  },
  proof_highest_education: {
    title: "Proof of Highest Education",
    file_type: "diploma",
  },
  current_visa_copy: {
    title: "Current Visa Copy",
    file_type: "visa",
  },
  i94_copy: {
    title: "I-94 Copy",
    file_type: "i94",
  },
  transfer_form: {
    title: "Transfer Form",
    file_type: "transfer",
  },
  f1_visa_copy: {
    title: "F1 Visa Copy",
    file_type: "f1_visa",
  },
  i20_previous_copy: {
    title: "I20 Previous Copy",
    file_type: "i20",
  },
  others: {
    title: "Others",
    file_type: "other",
  },
};

function StudentDocuments() {
  const { isLoading, data, updateData } = useFetchApi(getDocumentsList);
  const theme = useTheme();

  function documentsData(documentType) {
    return data !== null && data !== undefined && documentType in data
      ? data[documentType]
      : [];
  }

  return (
    <>
      <Hidden smUp>
        <Grid container spacing={3}>
          <Grid item sm={7} xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box
                component={Paper}
                elevation={2}
                marginBottom="1rem"
                width="100%"
                padding={1}
              >
                <Typography
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    marginBottom: "1rem",
                  }}
                >
                  Uploaded Files
                </Typography>
                {Object.keys(documentsTypes).map((documentType, index) => {
                  return (data &&
                    data[documentType] &&
                    Object.keys(data[documentType]).length) > 0 ? (
                    <Box key={index}>
                      <Typography
                        variant="h3"
                        style={{ marginTop: "1rem", marginLeft: "1.5rem" }}
                      >
                        {documentsTypes[documentType].title}
                      </Typography>
                      <UploadedFiles
                        title={documentsTypes[documentType].title}
                        documents={documentsData(documentType)}
                        file_type={documentsTypes[documentType].file_type}
                      />
                    </Box>
                  ) : null;
                })}
              </Box>
            )}
          </Grid>
          <Grid item sm={5} xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {Object.keys(documentsTypes).map((documentType, index) => (
                  <Document
                    key={index}
                    title={documentsTypes[documentType].title}
                    documents={documentsData(documentType)}
                    reloadFiles={updateData}
                    file_type={documentsTypes[documentType].file_type}
                  />
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid container spacing={3}>
          <Grid item sm={5} xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {Object.keys(documentsTypes).map((documentType, index) => (
                  <Document
                    key={index}
                    title={documentsTypes[documentType].title}
                    documents={documentsData(documentType)}
                    reloadFiles={updateData}
                    file_type={documentsTypes[documentType].file_type}
                  />
                ))}
              </>
            )}
          </Grid>
          <Grid item sm={7} xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box
                component={Paper}
                elevation={2}
                marginBottom="1rem"
                width="100%"
                padding={1}
              >
                <Typography
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    marginBottom: "1rem",
                  }}
                >
                  Uploaded Files
                </Typography>
                {Object.keys(documentsTypes).map((documentType, index) => {
                  return (data &&
                    data[documentType] &&
                    Object.keys(data[documentType]).length) > 0 ? (
                    <Box key={index}>
                      <Typography
                        variant="h3"
                        style={{ marginTop: "1rem", marginLeft: "1.5rem" }}
                      >
                        {documentsTypes[documentType].title}
                      </Typography>
                      <UploadedFiles
                        title={documentsTypes[documentType].title}
                        documents={documentsData(documentType)}
                        file_type={documentsTypes[documentType].file_type}
                      />
                    </Box>
                  ) : null;
                })}
              </Box>
            )}
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
}

export default StudentDocuments;
