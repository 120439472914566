import { DateTime } from "luxon";

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function printDateFriendly(date) {
  if (date) {
    if (date === "0000-00-00" || date === "0000-00-00 00:00:00") {
      return "Unset";
    }
    let localTimezone = DateTime.local().zoneName;
    return DateTime.fromSQL(date, {
      zone: "America/Los_Angeles",
    })
      .setZone(localTimezone)
      .toLocaleString();
  }
  return "N/A";
}

export function getNumberOfDays(month, year) {
  return new Date(year, month, 0).getDate();
}

export function printDateTimeFriendly(date) {
  if (date) {
    if (date === "0000-00-00" || date === "0000-00-00 00:00:00") {
      return "Unkown";
    }
    let localTimezone = DateTime.local().zoneName;
    return DateTime.fromSQL(date, {
      zone: "America/Los_Angeles",
    })
      .setZone(localTimezone)
      .toFormat("f");
  }
  return "N/A";
}
