import { all, call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { getAssignedClasses } from "services/api/Repos/AssignedClass/assignedClass";

function* getAssignedClassPages(action) {
  try {
    const response = yield call(() =>
      getAssignedClasses({
        page: action?.payload?.page ?? 1,
        load: action?.payload?.load ?? undefined,
      })
    );
    yield put({
      type: actions.GET_CLASSES_SUCCESS,
      payload: {
        data: response?.data ?? [],
        meta: response?.meta ?? {
          current_page: action?.payload?.page ?? 1,
          last_page: action?.payload?.page ?? 1,
          per_page: 5,
        },
      },
    });
  } catch (error) {
    yield put({
      type: actions.GET_CLASSES_FAILURE,
      payload: {
        message: error?.message ?? "",
      },
    });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_CLASSES, getAssignedClassPages)]);
}
