import React from "react";
import { DateTime } from "luxon";
import { useTheme } from "@material-ui/core/styles";

import { getPaid } from "services/api/Repos/Receipts";
import { getTestPrep } from "services/api/Repos/Class";
import { getAll } from "services/api/Repos/Session";
import { getLatestClass } from "services/api/Repos/Class";
import { getAttendanceColorProperty, getPropByString } from "helpers/misc";

export function useCalendarEvents() {
  const theme = useTheme();
  const [events, setEvents] = React.useState([]);

  const splitInWeekDaysEvents = (events) => {
    if (!Array.isArray(events)) {
      return [];
    }
    let response = [];
    for (let event of events) {
      var start_date = DateTime.fromSQL(event.start);
      var end_date = DateTime.fromSQL(event.end);
      if (start_date.startOf("day") >= end_date.startOf("day")) {
        response.push({
          ...event,
          start: start_date.toSQLDate(),
          end: start_date.toSQLDate(),
        });
        continue;
      }
      while (start_date.startOf("day") <= end_date.startOf("day")) {
        if (start_date.weekday >= 1 && start_date.weekday <= 5) {
          response.push({
            ...event,
            start: start_date.toSQLDate(),
            end: start_date.toSQLDate(),
          });
        }
        start_date = start_date.plus({ day: 1 });
      }
    }
    return response;
  };

  const createTestPrepEvents = (testPrepArray, backgroundColor) => {
    if (!Array.isArray(testPrepArray)) {
      return [];
    }
    return testPrepArray.flatMap((testPrep) => {
      if (
        !Array.isArray(testPrep.test_prep_dates) ||
        testPrep.test_prep_dates.length === 0
      ) {
        return [];
      }
      var response = [];
      testPrep.test_prep_dates.forEach((date) => {
        if (!Array.isArray(date?.dates)) {
          return response;
        }
        date.dates.forEach((element) => {
          if (element?.subject?.subject && element?.date) {
            response.push({
              title: `Test: ${element?.subject?.subject ?? "N/A"}`,
              start: element.date,
              end: element.date,
              backgroundColor: backgroundColor,
              borderColor: backgroundColor,
              extendedProps: {
                ...element,
                eventType: "test_prep",
                date_string: element.date,
              },
            });
          }
        });
      });
      return response;
    });
  };

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const [
          paidResponse,
          testPrepResponse,
          allSessionResponse,
          latestClassResponse,
        ] = await Promise.all([
          getPaid().catch((error) => console.log(error)),
          getTestPrep().catch((error) => console.log(error)),
          getAll().catch((error) => console.log(error)),
          getLatestClass().catch((error) => console.log(error)),
        ]);
        if (isMounted) {
          var eventsArray = [];
          if (paidResponse && !paidResponse.error) {
            eventsArray = paidResponse.data.map((paid) => {
              return {
                title: paid.transaction_program.transactionProgram,
                start: paid.start_date,
                end: paid.end_date,
                backgroundColor: theme.palette.success.main,
                borderColor: theme.palette.success.main,
                extendedProps: {
                  program: paid.transaction_program,
                  schedule: paid.transaction_schedule,
                  description: paid.transaction_description,
                  start_date_string: paid.start_date,
                  end_date_string: paid.end_date,
                  eventType: "paid_days",
                },
              };
            });
            eventsArray = splitInWeekDaysEvents(eventsArray);
          }

          if (testPrepResponse && !testPrepResponse.error) {
            var testPrepArray = createTestPrepEvents(
              testPrepResponse?.data ?? [],
              theme.palette.info.main
            );
            eventsArray.push(...testPrepArray);
          }

          if (allSessionResponse && !allSessionResponse.error) {
            var sessionsEvents = [];
            sessionsEvents = allSessionResponse.data.map((session) => {
              return {
                title: session.session_name,
                groupId: session.session_name,
                start: session.start_date,
                end: session.end_date,
                backgroundColor: theme.palette.info.light,
                borderColor: theme.palette.info.light,
                display: "background",
                extendedProps: {
                  ...session,
                  eventType: "session_background",
                },
              };
            });

            sessionsEvents = splitInWeekDaysEvents(sessionsEvents);
            eventsArray.push(...sessionsEvents);
          }

          if (latestClassResponse && !latestClassResponse.error) {
            const attendanceOut = latestClassResponse.data.attendance;

            var attendanceEvents = [];
            attendanceEvents = attendanceOut.attendances.map((attendance) => {
              var attendanceValue = "";
              var backgroundColor = "";
              for (var index = 0; index < attendance.values.length; index++) {
                if (index === 0) {
                  attendanceValue = attendance.values[index].attendance;
                  backgroundColor = getPropByString(
                    theme.palette,
                    getAttendanceColorProperty(
                      attendance.values[index].attendance
                    )
                  );
                  continue;
                }
                if (attendanceValue !== attendance.values[index].attendance) {
                  backgroundColor = theme.palette.grey[600];
                  attendanceValue = "Attendances";
                  break;
                }
              }
              return {
                title: attendanceValue,
                start: attendance.date,
                end: attendance.date,
                backgroundColor: !!attendanceOut.pending_payment
                  ? theme.palette.error.main
                  : backgroundColor,
                borderColor: !!attendanceOut.pending_payment
                  ? theme.palette.error.main
                  : backgroundColor,
                display: "list-item",
                extendedProps: {
                  values: attendance.values,
                  eventType: "attendance",
                  pending_payment: !!attendanceOut.pending_payment,
                  class: latestClassResponse.data,
                },
              };
            });

            eventsArray.push(...attendanceEvents);
          }

          setEvents(eventsArray);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [theme.palette]);

  return events;
}
