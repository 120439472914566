export function parseHtml5String(bodyText) {
  if (typeof bodyText === "string") {
    const parser = new DOMParser();
    return (
      parser.parseFromString(bodyText, "text/html").body.textContent ?? bodyText
    );
  }
  return bodyText;
}

export function getAttendanceColorProperty(attendanceSubtitle) {
  if (typeof attendanceSubtitle !== "string") {
    return "grey.100";
  }
  attendanceSubtitle = attendanceSubtitle.toUpperCase();
  switch (attendanceSubtitle) {
    case "A": {
      return "error.main";
    }
    case "P": {
      return "info.main";
    }
    case "T": {
      return "grey.300";
    }
    case "L": {
      return "grey.600";
    }
    case "H": {
      return "success.main";
    }
    case "G": {
      return "warning.main";
    }
    default: {
      return "grey.100";
    }
  }
}

export function getPropByString(obj, propString) {
  if (!propString) return obj;

  var prop,
    props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

export function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}
