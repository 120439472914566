import React, { useContext } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import Person from "@material-ui/icons/Person";

import { logout as logoutApi } from "services/api/Auth";
import { AuthContext } from "context";

// core components
import componentStyles from "assets/theme/components/navbar-dropdown.js";

const LinkMenuItem = React.forwardRef((props, ref) => {
  const { to, ...rest } = props;
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <MenuItem {...rest} innerRef={ref} />
    </Link>
  );
});

const useStyles = makeStyles(componentStyles);

export default function NavbarDropdown() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout, studentInfo } = useContext(AuthContext);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const hangleLogout = () => {
    logoutApi().catch((error) => {
      console.log("Error making logout to the API, removing user info anyway");
      console.log(error);
    });
    handleMenuClose();
    logout();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {studentInfo.read_orientation ? (
        <div>
          <Box
            display="flex!important"
            alignItems="center!important"
            component={LinkMenuItem}
            onClick={handleMenuClose}
            to="/profile"
          >
            <Box
              component={Person}
              width="1.25rem!important"
              height="1.25rem!important"
              marginRight="1rem"
            />
            <span>My profile</span>
          </Box>
          <Divider component="div" classes={{ root: classes.dividerRoot }} />
          <Box
            display="flex!important"
            alignItems="center!important"
            component={MenuItem}
            onClick={hangleLogout}
          >
            <Box
              component={DirectionsRun}
              width="1.25rem!important"
              height="1.25rem!important"
              marginRight="1rem"
            />
            <span>Logout</span>
          </Box>
        </div>
      ) : (
        <div>
          <Box
            display="flex!important"
            alignItems="center!important"
            component={MenuItem}
            onClick={hangleLogout}
          >
            <Box
              component={DirectionsRun}
              width="1.25rem!important"
              height="1.25rem!important"
              marginRight="1rem"
            />
            <span>Logout</span>
          </Box>
        </div>
      )}
    </Menu>
  );

  return (
    <>
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >
        <Avatar
          alt="..."
          src={
            studentInfo?.photo_url
              ? `https://admin.languagesystems.edu/uploads/students/profile/${studentInfo?.photo_url}`
              : ""
          }
          classes={{
            root: classes.avatarRoot,
          }}
        >
          {studentInfo.first_name.charAt(0) + studentInfo.last_name.charAt(0)}
        </Avatar>
        <Hidden
          smDown
        >{`${studentInfo.first_name} ${studentInfo.last_name}`}</Hidden>
      </Button>
      {renderMenu}
    </>
  );
}
