import React from "react";
import { getLatestClass } from "services/api/Repos/Class";

export function useLatestClass() {
  const [latestClass, setLatestClass] = React.useState();

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const classResponse = await getLatestClass();
        if (isMounted && !classResponse.error) {
          setLatestClass(classResponse.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return latestClass;
}
