import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { useField } from "formik";

export const FormikTextField = (props) => {
  const AdormentComponent = props.adormentComponent;
  const {
    autoComplete,
    InputLabelProps,
    label,
    name,
    type,
    placeholder,
    multiline,
    maxRows,
    rows,
    className,
  } = props;
  const [field, meta] = useField({
    autoComplete,
    InputLabelProps,
    label,
    name,
    type,
    placeholder,
  });
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      {AdormentComponent ? (
        <TextField
          {...field}
          autoComplete={autoComplete}
          InputLabelProps={InputLabelProps}
          label={label}
          name={name}
          type={type}
          className={className}
          placeholder={placeholder}
          helperText={errorText}
          error={!!errorText}
          multiline={multiline}
          rowsMax={maxRows}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AdormentComponent />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          {...field}
          autoComplete={autoComplete}
          InputLabelProps={InputLabelProps}
          label={label}
          name={name}
          className={className}
          type={type}
          placeholder={placeholder}
          helperText={errorText}
          error={!!errorText}
          multiline={multiline}
          rowsMax={maxRows}
          rows={rows}
        />
      )}
    </>
  );
};

FormikTextField.defaultProps = {
  adormentComponent: null,
};

FormikTextField.propTypes = {
  adormentComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
};
