import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";

import { routes, orientationRoute } from "routes/routes";

import componentStyles from "assets/theme/layouts/admin.js";
import { AuthContext } from "context";

const useStyles = makeStyles(componentStyles);

const Main = (props) => {
  const { children } = props;
  const location = useLocation();
  const classes = useStyles();
  const { studentInfo } = useContext(AuthContext);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <Sidebar
        routes={studentInfo.read_orientation !== 1 ? orientationRoute : routes}
        logo={
          studentInfo.read_orientation !== 1
            ? {
                innerLink: "/orientation",
                imgSrc: require("../assets/img/brand/argon-react.png").default,
                imgAlt: "...",
              }
            : {
                innerLink: "/dashboard",
                imgSrc: require("../assets/img/brand/argon-react.png").default,
                imgAlt: "...",
              }
        }
        dropdown={<NavbarDropdown />}
      />
      <Box position="relative" className={classes.mainContent}>
        <AdminNavbar brandText="Language Systems International" />
        {children}
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <AdminFooter />
        </Container>
      </Box>
    </>
  );
};

export default Main;
