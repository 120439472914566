import { axios } from "services/api/Config";

export const getAssignedClasses = (params) => {
  const { page, load } = params;
  return axios.get("assigned_class/all", {
    params: {
      page,
      with: load,
    },
  });
};
