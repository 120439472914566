import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { uploadDocumentFile } from "services/files/requests/Documents";
import { AuthContext } from "context";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Document({ title, file_type, reloadFiles }) {
  const theme = useTheme();
  const { studentInfo } = useContext(AuthContext);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleFileUpload = async () => {
    const requestParams = {
      student_id: studentInfo.id,
      student_filenumber: studentInfo.file_number,
      location_id: studentInfo.location_id,
      file_type: file_type,
      file: acceptedFiles[0],
    };

    const response = await uploadDocumentFile(requestParams);

    if (!response.data?.isError ?? false) {
      reloadFiles();
    }
  };

  const acceptedFileItems = acceptedFiles.map((file, index) => (
    <li key={index}>
      {file.path}
      <Tooltip title="Send file" arrow>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleFileUpload}
        >
          <CloudUploadIcon style={{ height: "3rem" }} />
        </IconButton>
      </Tooltip>
    </li>
  ));

  return (
    <Box
      component={Paper}
      elevation={2}
      marginBottom="1rem"
      width="100%"
      padding={1}
    >
      <Typography>{title}</Typography>

      <Box>
        <Typography
          variant="body1"
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            marginBottom: "1rem",
          }}
        >
          Send Files
        </Typography>
        <section className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>
              Click here and choose your documents to upload or simply drop
              here.
            </p>
            <em>(Only *.jpeg and *.png and *.pdf files will be accepted)</em>
          </div>
          {acceptedFiles.length > 0 && (
            <aside>
              <h4>Selected File</h4>
              <Typography variant="body1">
                Remember to click on upload button to send the file
              </Typography>
              <ul>{acceptedFileItems}</ul>
            </aside>
          )}
        </section>
      </Box>
    </Box>
  );
}

Document.propTypes = {
  title: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      file_name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default Document;
