import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import { Form, Formik } from "formik";
import { FormikTextField } from "components/FormFields";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  })
);

export const MessageInput = (props) => {
  const classes = useStyles();
  const { onSubmit } = props;
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        message: "",
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={classes.wrapForm} noValidate autoComplete="off">
          <FormikTextField
            label="Send a message to LSI staff"
            placeholder="Your message here"
            className={classes.wrapText}
            name="message"
            fullwidth
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={3}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            <SendIcon />
          </Button>
        </Form>
      )}
    </Formik>
  );
};
