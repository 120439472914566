import axios from "axios";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true
});

jwtAxios.interceptors.response.use(
  (res) => {
    return {
      data: res.data?.data ?? res.data,
      meta: res.data?.meta ?? undefined,
      status: res.status,
      message: res.data?.message,
    };
  },
  (err) => {
    if (err.response) {
      //Server responded with some error code
      if (err.response.status === 401) {
        //TODO implement refresh token if user set remember on login
        setAuthToken();
      }
      return Promise.reject({
        data: err.response?.data ?? {},
        message:
          err.response.data?.message ??
          "Request failed with status code " + (err.response?.status ?? 500),
        status: err.response.status ?? 500,
        error:
          err.response?.data?.error ??
          err.response.data?.errors ??
          err.response.errors ??
          true,
      });
    } else if (err.request) {
      // The request was made but no response was received
      return Promise.reject({
        message: "Could not receive server response: " + err.message,
        request: err.request,
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject({
        message: err.message,
      });
    }
  }
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
  }
};

export default jwtAxios;
