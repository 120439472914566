import React from "react";
import { getAllPaginated } from "services/api/Repos/AttendanceDates";

export function useAttendanceDates() {
  const [attendanceDates, setAttendanceDates] = React.useState([]);
  const [validDates, setValidDates] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const currentSessionresponse = await getAllPaginated(1);
        if (isMounted && !currentSessionresponse.error) {
          var validDatestmp = [];
          setAttendanceDates(currentSessionresponse.data);
          for (var session of currentSessionresponse.data) {
            for (var date of session.dates) {
              if (!date.is_holyday) {
                validDatestmp.push(date.date);
              }
            }
          }

          setValidDates(validDatestmp);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return { attendanceDates, validDates };
}
