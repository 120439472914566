import { all, call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import {
  getAllMessages,
  sendMessage,
  readMessages,
} from "services/api/Repos/Messages";

function* getMessagesSaga(action) {
  try {
    const response = yield call(() => getAllMessages());
    yield put({
      type: actions.GET_MESSAGES_SUCCESS,
      payload: {
        data: response?.data ?? [],
      },
    });
  } catch (error) {
    yield put({
      type: actions.GET_MESSAGES_FAILURE,
      payload: {
        message:
          error?.message ?? "Error trying to get messages, try again later",
      },
    });
  }
}

function* sendMessageSaga(action) {
  try {
    const response = yield call(() => sendMessage(action.payload.message));
    yield put({ type: actions.GET_MESSAGES });
  } catch (error) {
    yield put({
      type: actions.SEND_MESSAGE_FAILURE,
      payload: {
        message:
          error?.message ?? "Error trying to send message, try again later",
      },
    });
  }
}

function* readMessagesSaga(action) {
  yield call(() => readMessages());
  yield put({ type: actions.GET_MESSAGES });
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_MESSAGES, getMessagesSaga)]);
  yield all([takeLatest(actions.SEND_MESSAGE, sendMessageSaga)]);
  yield all([takeLatest(actions.READ_MESSAGES, readMessagesSaga)]);
}
