import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import componentStyles from "assets/theme/views/admin/profile.js";
import { Box, Checkbox, Divider, Grid, Typography } from "@material-ui/core";
import { DateTime } from "luxon";

const useStyles = makeStyles(componentStyles);

function KeyValueGridDataTable({ title, values, valuesPerRow }) {
  const classes = useStyles();
  const theme = useTheme();

  const getValueData = useCallback((value, type) => {
    if (type === "date" || type === "datetime") {
      if (value === "0000-00-00 00:00:00" || value === "0000-00-00") {
        return "-";
      }
      return DateTime.fromSQL(value).toLocaleString() ?? "-";
    }
    return value;
  }, []);

  return (
    <>
      {title && (
        <>
          <Box
            component={Divider}
            marginBottom="1.5rem!important"
            marginTop="1.5rem!important"
          />
          <Box
            component={Typography}
            variant="h6"
            color={theme.palette.gray[600] + "!important"}
            paddingTop=".25rem"
            paddingBottom=".25rem"
            fontSize=".75rem!important"
            letterSpacing=".04em"
            marginBottom="1.5rem!important"
            classes={{ root: classes.typographyRootH6 }}
          >
            {title}
          </Box>
        </>
      )}
      <Grid container>
        {values.map((value, index) => (
          <Grid key={index} item xs={12} sm={12 / valuesPerRow}>
            <Box textAlign="center" padding=".2rem">
              <Box
                component="span"
                fontSize="1.1rem"
                fontWeight="700"
                display="block"
                letterSpacing=".025em"
                className={classes.grayTitle}
              >
                {(value?.type ?? "string") === "checkbox" ? (
                  <Checkbox
                    size="small"
                    disabled
                    checked={!!value?.value ?? false}
                  />
                ) : (
                  <>
                    {value?.value
                      ? getValueData(
                          value?.value ?? "-",
                          value?.type ?? "string"
                        )
                      : "-"}
                  </>
                )}
              </Box>
              <Box
                component="span"
                fontSize=".875rem"
                color={theme.palette.gray[500]}
              >
                {value?.label ? value?.label : "-"}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

KeyValueGridDataTable.defaultProps = {
  valuesPerRow: 2,
};

KeyValueGridDataTable.propTypes = {
  title: PropTypes.string,
  valuesPerRow: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      type: PropTypes.oneOf(["date", "datetime", "string", "checkbox"]),
    })
  ).isRequired,
};

export default KeyValueGridDataTable;
