const actions = {
  GET_MESSAGES: "GET_MESSAGES",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  GET_MESSAGES_FAILURE: "GET_MESSAGES_FAILURE",
  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
  READ_MESSAGES: "READ_MESSAGES",
};

export default actions;
