import React, { useCallback } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { DatePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";

const scheduleOptions = [
  {
    value: "morning",
    label: "Morning",
  },
  {
    value: "afternoon",
    label: "Afternoon",
  },
  {
    value: "evening",
    label: "Evening",
  },
];

const PaymentForm = ({
  programSale,
  handleChange,
  isValidStartDate,
  isValidEndDate,
  formValues,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  selectedPrice,
  handleContinueButton,
  canChooseEndDate,
}) => {
  const getProgramSaleOptions = useCallback(() => {
    if (Array.isArray(programSale)) {
      return programSale.map((element) => {
        return {
          value: element.program_id,
          label: element.program_name,
        };
      });
    }
    return [];
  }, [programSale]);

  const shouldDisableStartDate = (date) => {
    const isValidDate = isValidStartDate(date);
    return !isValidDate;
  };

  const shouldDisableEndDate = (date) => {
    const isValidDate = isValidEndDate(date);
    return !isValidDate;
  };

  return (
    <Grid container spacing={2} alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                name="program"
                id="program"
                value={formValues.program}
                onChange={handleChange}
                required
                label="Program"
                select
                placeholder="Program"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="" disabled>
                  Program
                </MenuItem>
                {getProgramSaleOptions().map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                    disabled={!!option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                name="schedule"
                id="schedule"
                required
                onChange={handleChange}
                value={formValues.schedule}
                select
                label="Schedule"
                placeholder="Schedule"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="" disabled>
                  Schedule
                </MenuItem>
                {scheduleOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                    disabled={!!option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <DatePicker
                InputLabelProps={{ shrink: true }}
                openTo="date"
                id="date_initial"
                onChange={setStartDate}
                value={startDate}
                shouldDisableDate={shouldDisableStartDate}
                minDate={DateTime.now()}
                disablePast
                disabled={
                  formValues.program === "" || formValues.schedule === ""
                }
                name="date_initial"
                required
                label="Start date"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <DatePicker
                InputLabelProps={{ shrink: true }}
                openTo="date"
                id="date_final"
                name="date_final"
                onChange={setEndDate}
                value={endDate}
                minDate={startDate}
                disablePast
                disabled={!canChooseEndDate}
                shouldDisableDate={shouldDisableEndDate}
                required
                label="End date"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Number of Weeks"
                value={selectedPrice.number_of_weeks || ""}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Price credit card"
                value={selectedPrice.price_credit_card || ""}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Price cash"
                value={selectedPrice.price_cash || ""}
                helperText="Only available with cash at one of our campuses"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="1.5rem"
          marginBottom="1.5rem"
        >
          <Button
            onClick={handleContinueButton}
            type="submit"
            color="primary"
            variant="contained"
            disabled={selectedPrice.price_credit_card === ""}
          >
            Continue to payment
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaymentForm;
