import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import theme from "assets/theme/theme.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import { AppRoutes } from "routes/AppRoutes";

import { useAuth } from "hooks";

import { AuthContext } from "context";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store from "./redux/store";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const App = () => {
  const {
    token,
    loadUser,
    logout,
    student_id,
    location_id,
    studentInfo,
    isLoading,
    setStudentInfo,
  } = useAuth();

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <AuthContext.Provider
            value={{
              isLoading,
              isLoggedIn: !!token,
              setStudentInfo,
              student_id,
              location_id,
              token,
              loadUser,
              logout,
              studentInfo,
            }}
          >
            <Provider store={store()}>
              <PayPalScriptProvider
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                  currency: "USD",
                  commit: true,
                  intent: "authorize",
                }}
              >
                <ErrorBoundary>
                  <AppRoutes isAuthenticated={!!token} isLoading={isLoading} />
                </ErrorBoundary>
              </PayPalScriptProvider>
            </Provider>
          </AuthContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
