import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  Tab,
  Tabs,
  withStyles,
  Grid,
} from "@material-ui/core";
import componentStyles from "assets/theme/components/assigned-class-attendances-table";
import PropTypes from "prop-types";
import { printDateFriendly } from "helpers/date";
import AvatarGroupWithTooltip from "./AvatarGroupWithTooltip";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";

const useStyles = makeStyles(componentStyles);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
    fontSize: ".875rem",
    fontWeight: 500,
    padding: ".75rem 1rem",
    transition: "all .15s ease",
    boxShadow: "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: ".375rem",
    display: "block",
    textAlign: "center",
    flex: "1 1 auto",
    maxWidth: "unset",
    "&$selected": {
      color: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.primary.main + "!important",
    },
    "&:not(:last-child)": {
      marginRight: "1rem",
    },
    "& svg": {
      width: "1rem!important",
      height: "1rem!important",
      position: "relative",
      top: "2px",
    },
  },
  wrapper: {
    flexDirection: "row",
  },
  labelIcon: {
    minHeight: "unset",
    paddingTop: ".75rem",
  },
}))((props) => <Tab disableRipple {...props} />);

const RenderGrades = ({ gradesList }) => {
  return (
    <AvatarGroupWithTooltip
      avatars={gradesList.map((grade) => {
        return {
          tooltipText:
            grade?.subject ?? false ? (
              <>
                Subject: {grade?.subject?.subject ?? "N/A"} <br />
                Date: {printDateFriendly(grade?.date ?? "") ?? "N/A"} <br />
              </>
            ) : (
              `Date: ${printDateFriendly(grade?.date ?? "") ?? "N/A"}`
            ),
          avatarFallbackText: grade?.grade ?? "N/A",
        };
      })}
    />
  );
};

function AssignedClassGrades({ assignedClass, color }) {
  const classes = useStyles();
  const theme = useTheme();
  const [gradeSubjectTab, setGradeSubjectTab] = useState(0);

  const handleChange = (event, newValue) => {
    setGradeSubjectTab(newValue);
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Typography align="center">
            <b>Grades for</b>{" "}
            <Box
              component="span"
              color={(color ?? "") !== "" ? color : theme.palette.info.main}
            >
              {assignedClass?.level.level_name ?? "N/A"} |{" "}
              {assignedClass?.session.session_name ?? "N/A"}
            </Box>
            <br />
            <b>Assigned at:</b>{" "}
            <Box
              component="span"
              color={(color ?? "") !== "" ? color : theme.palette.info.main}
            >
              {printDateFriendly(assignedClass?.assign_date)}
            </Box>
            <br />
            <b>Total Attendance percent:</b>{" "}
            <Box
              component="span"
              color={(color ?? "") !== "" ? color : theme.palette.info.main}
            >
              {assignedClass?.attendance.attendance_percent ?? "N/A"}%
            </Box>
          </Typography>
        }
        titleTypographyProps={{
          component: Box,
          marginBottom: "0!important",
          variant: "h3",
        }}
      ></CardHeader>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <TabContext value={gradeSubjectTab + ""}>
          <StyledTabs
            value={gradeSubjectTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
            textColor="secondary"
          >
            {assignedClass?.class_type === "test_prep" ? (
              <StyledTab label="Test Preparation" />
            ) : (
              (assignedClass?.grades ?? []).map((grade, index) => (
                <StyledTab
                  key={index}
                  icon={
                    <Box
                      //component={grade?.subject?.icon ?? 'placeholderSubjectIcon'}
                      marginRight=".1rem"
                    />
                  }
                  label={grade?.subject?.subject ?? "N/A"}
                />
              ))
            )}
          </StyledTabs>
          {assignedClass?.class_type === "test_prep" ? (
            <TabPanel value="0">
              <Grid container>
                {(assignedClass?.grades ?? []).map((grade, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography>Practice Prep</Typography>
                      <RenderGrades gradesList={grade?.practice_prep ?? []} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Participation</Typography>
                      <RenderGrades gradesList={grade?.participation ?? []} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Test</Typography>
                      <RenderGrades gradesList={grade?.test ?? []} />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </TabPanel>
          ) : (
            (assignedClass?.grades ?? []).map((grade, index) => (
              <TabPanel value={"" + index} key={index}>
                <React.Fragment key={index}>
                  <Grid item xs={12}>
                    <Typography>Test</Typography>
                    <RenderGrades gradesList={grade?.test ?? []} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Homework</Typography>
                    <RenderGrades gradesList={grade?.homework ?? []} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Participation</Typography>
                    <RenderGrades gradesList={grade?.participation ?? []} />
                  </Grid>
                </React.Fragment>
              </TabPanel>
            ))
          )}
        </TabContext>
      </CardContent>
    </Card>
  );
}

AssignedClassGrades.propTypes = {
  assignedClass: PropTypes.shape({
    class_type: PropTypes.oneOf(["test_prep", "esl"]),
    grades: PropTypes.arrayOf(
      PropTypes.oneOf([
        PropTypes.shape({
          subject: PropTypes.shape({
            subject: PropTypes.string,
            program_id: PropTypes.number,
            icon: PropTypes.string,
          }),
          test: PropTypes.shape({
            grade: PropTypes.string,
            date: PropTypes.string,
          }),
          participation: PropTypes.shape({
            grade: PropTypes.string,
            date: PropTypes.string,
          }),
          homework: PropTypes.shape({
            grade: PropTypes.string,
            date: PropTypes.string,
          }),
        }),
        PropTypes.shape({
          practice_prep: PropTypes.shape({
            grade: PropTypes.string,
            date: PropTypes.string,
            subject: PropTypes.shape({
              subject: PropTypes.string,
              program_id: PropTypes.number,
              icon: PropTypes.string,
            }),
          }),
          participation: PropTypes.shape({
            grade: PropTypes.string,
            date: PropTypes.string,
          }),
          test: PropTypes.shape({
            grade: PropTypes.string,
            date: PropTypes.string,
          }),
        }),
      ])
    ),
    level: PropTypes.shape({
      level_name: PropTypes.string,
    }),
    session: PropTypes.shape({
      session_name: PropTypes.string,
      start_date: PropTypes.string,
      year: PropTypes.string,
    }),
    assign_date: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
};

export default AssignedClassGrades;
