// Private route restrict to access public pages after login.
import { Backdrop, makeStyles } from "@material-ui/core";
import { Redirect, Route } from "react-router-dom";
import { routes } from "./routes";
import "animate.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background:
      "linear-gradient(87deg," + theme.palette.info.main + ",#be0061)",
  },
}));

export function PrivateRoute({
  children,
  isAuthenticated,
  isLoading,
  ...rest
}) {
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <img
          alt="logo-lsi"
          className="animate__animated animate__pulse animate__infinite"
          src={require("../assets/img/brand/argon-react.png").default}
        />
      </Backdrop>
      {!isLoading && (
        <Route
          {...rest}
          render={({ location }) =>
            isAuthenticated ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
}

// Public route restrict to access authenticated pages before login.
export function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const minimalLayoutRoutes = routes.filter(
  (route) => route.layout === "minimal"
);

export const mainLayoutRoutes = routes.filter(
  (route) => route.layout === "main"
);
