import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStyles from "assets/theme/views/admin/tables.js";
import { printDateFriendly } from "helpers/date";
import "animate.css";

const useStyles = makeStyles(componentStyles);

function ReceiptTable(props) {
  const { receipt } = props;
  const classes = useStyles();
  const theme = useTheme();
  const receiptRef = useRef(null); //to be able to access the current one
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (props.anchorId === `receipt_${receipt.id}`) {
      receiptRef.current.scrollIntoView({ behavior: "smooth" });
      setIsSelected(true);
    }
  }, [props.anchorId, receipt]); //same as ComponentDidMount

  return (
    <div
      id={`receipt_${receipt.id}`}
      ref={receiptRef}
      className={
        isSelected ? "animate__animated animate__flash animate__delay-1s" : ""
      }
    >
      <Box
        component={Table}
        alignItems="center"
        marginBottom="0!important"
        borderColor={theme.palette.info.main}
      >
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Receipt Number:
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              {receipt.number}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Method:
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              {Array.isArray(receipt.payment_details) &&
              receipt.payment_details.length > 0 ? (
                receipt.payment_details
                  .map((detail) => {
                    return `${detail.method?.method} ($ ${detail.payment_amount}) `;
                  })
                  .join(", ")
              ) : (
                <>N/A</>
              )}
            </TableCell>
          </TableRow>
          <TableRow height="28">
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              <strong>Receipt Date:</strong>
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              {printDateFriendly(receipt.date)}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              <strong>Approved Code:</strong>
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              {Array.isArray(receipt.payment_details) &&
              receipt.payment_details.length > 0 ? (
                receipt.payment_details
                  .map((detail) => {
                    return detail.approved_code;
                  })
                  .join(", ")
              ) : (
                <>N/A</>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      </Box>
      <Box
        component={Table}
        alignItems="center"
        marginTop="0!important"
        marginBottom="3rem!important"
      >
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Program
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Description
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Schedule
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Number of Weeks bought
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Start Date
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              End Date
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              Paid
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {receipt.transaction_program?.transactionProgram}
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {receipt.transaction_description?.transactionDecription}
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {receipt.transaction_schedule?.schedule}
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {receipt.number_of_weeks}
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {printDateFriendly(receipt.start_date)}
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {printDateFriendly(receipt.end_date)}
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              {receipt?.order !== null
                ? receipt.order?.order_value ?? "N/A"
                : receipt?.paid ?? "N/A"}
            </TableCell>
          </TableRow>
          {Array.isArray(receipt.receipts) &&
            receipt.receipts.map((receipt, index) => (
              <TableRow key={index}>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {receipt.transaction_program?.transactionProgram}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {receipt.transaction_description?.transactionDecription}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {receipt.transaction_schedule?.schedule}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {receipt.number_of_weeks}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {printDateFriendly(receipt.start_date)}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {printDateFriendly(receipt.end_date)}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {receipt.paid}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Box>
    </div>
  );
}

export default ReceiptTable;
