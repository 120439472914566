import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import cx from "clsx";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { useBlogTextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/blog";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";

import { parseHtml5String } from "helpers/misc";

const useStylesCard = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(2), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    maxWidth: 600,
    marginLeft: "auto",
    overflow: "initial",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: spacing(2),
    marginBottom: spacing(4),
    [breakpoints.down("xs")]: {
      marginBottom: spacing(2),
    },
  },
  text: {
    maxWidth: 400,
  },
  media: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: "48%",
    borderRadius: spacing(2),
    backgroundColor: "#fff",
    position: "relative",
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage:
        "linear-gradient(87deg," + palette.info.main + ",#be0061)",
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  button: {
    backgroundImage: "linear-gradient(87deg," + palette.info.main + ",#be0061)",
    boxShadow: "0px 2px 5px " + palette.info.dark,
    borderRadius: 100,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#ffffff",
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: "initial",
  },
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function AnnouncementsCarousel({ announcements }) {
  const shadowStyles = useOverShadowStyles();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = announcements.length;

  const styles = useStylesCard();
  const { ...contentStyles } = useBlogTextInfoContentStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const getAnnouncementImageSrc = (imageName) => {
    if (!imageName) {
      return "https://source.unsplash.com/random";
    }
    return `${process.env.REACT_APP_IMG_SRC_PREFIX_URI}/announcement/${imageName}`;
  };

  return (
    <>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        wrap="nowrap"
      >
        {announcements.map((announcement, key) => (
          <Card key={key} className={cx(styles.root, shadowStyles.root)}>
            <CardMedia
              className={styles.media}
              src={getAnnouncementImageSrc(announcement?.event_image)}
            />
            <CardContent className={styles.text}>
              <TextInfoContent
                classes={{
                  overline: contentStyles.overline,
                  heading: contentStyles.heading,
                  body: contentStyles.body,
                }}
                overline={announcement?.event_date_text}
                heading={announcement?.event_title}
                body={parseHtml5String(announcement?.event_data)}
              />
              {announcement?.event_link && (
                <Button
                  target="_blank"
                  href={announcement.event_link}
                  className={styles.button}
                >
                  {announcement?.event_link_title ?? announcement.event_link}
                </Button>
              )}
            </CardContent>
          </Card>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            variant="text"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            variant="text"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </>
  );
}

AnnouncementsCarousel.defaultProps = {
  announcements: [
    {
      event_title: "Questions?",
      event_data:
        "Please email Sylvia at sylvia@languagesystems.edu or send a message through this system if you have any questions regarding your level for Session B.&#13;&#10;&#13;&#10;Session B starts on Monday, May 4",
      event_image: "1a.jpg",
      event_link: "",
      event_link_title: "",
      event_date_text: "Thursday, November 21, 2019",
    },
  ],
};

AnnouncementsCarousel.propTypes = {
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      event_title: PropTypes.string,
      event_data: PropTypes.string,
      event_date_text: PropTypes.string,
      event_link: PropTypes.string,
      event_image: PropTypes.string,
      event_link_title: PropTypes.string,
    })
  ),
};

export default AnnouncementsCarousel;
