import React from "react";
import componentStyles from "assets/theme/views/admin/orientation.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles);

export default function YoutubeComponent({ video_id }) {
  const classes = useStyles();

  return (
    <div className={classes.video_wrapper}>
      <iframe
        title="ytplayer"
        id="ytplayer"
        type="text/html"
        className={classes.iframe}
        src={`https://www.youtube.com/embed/${video_id}?rel=0&cc_load_policy=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}
