import React, { useContext, useCallback, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import LocationOn from "@material-ui/icons/LocationOn";

// core components
import UserHeader from "components/Headers/UserHeader.js";

import componentStyles from "assets/theme/views/admin/profile.js";
import boxShadows from "assets/theme/box-shadow.js";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// @material-ui/lab components
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
// @material-ui/icons components
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DescriptionIcon from "@material-ui/icons/Description";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GradeIcon from "@material-ui/icons/Grade";
import { AuthContext } from "context";
import KeyValueGridDataTable from "components/Tables/KeyValueGridDataTable";
import { useFetchApi } from "hooks";
import { getI20Info as getI20InfoRequest } from "services/api/Repos/Student";
import { CircularProgress } from "@material-ui/core";
import AttendanceTable from "./components/AttendanceTable";
import GradesTable from "./components/GradesTable";

const useStyles = makeStyles(componentStyles);

const basicInformationKeys = {
  first_name: {
    label: "First Name",
    type: "string",
  },
  last_name: {
    label: "Last Name",
    type: "string",
  },
  middle_name: {
    label: "Middle Name",
    type: "string",
  },
  gender: {
    label: "Gender",
    type: "string",
  },
  birth_date: {
    label: "Birthdate",
    type: "date",
  },
  email: {
    label: "E-mail",
    type: "string",
  },
  type: {
    label: "Type",
    type: "string",
  },
  location: {
    label: "Location",
    type: "string",
  },
  nationality: {
    label: "Citizenship",
    type: "string",
  },
  county_of_birth: {
    label: "Country of Birth",
    type: "string",
  },
  city_of_birth: {
    label: "City of Birth",
    type: "string",
  },
};

const homeCountryInfo = {
  f_city: {
    label: "City",
    type: "string",
  },
  f_state: {
    label: "State",
    type: "string",
  },
  f_address: {
    label: "Street",
    type: "string",
  },
  f_country: {
    label: "Citizenship",
    type: "string",
  },
  f_zip_code: {
    label: "Postal Code",
    type: "string",
  },
  f_phone_number: {
    label: "Phone Number",
    type: "string",
  },
  f_second_phone_number: {
    label: "Second Phone Number",
    type: "string",
  },
};

const usAddressInfo = {
  us_city: {
    label: "City",
    type: "string",
  },
  us_state: {
    label: "State/Province",
    type: "string",
  },
  us_address: {
    label: "Street",
    type: "string",
  },
  us_zip_code: {
    label: "Zip Code",
    type: "string",
  },
  us_phone_number: {
    label: "Phone Number",
    type: "string",
  },
  us_second_phone_number: {
    label: "Second Phone Number",
    type: "string",
  },
};

const bankStatementInfo = {
  personal_funds: {
    label: "Personal Funds",
    type: "string",
  },
  funds_from_another: {
    label: "Funds from Another Source",
    type: "string",
  },
  funds_from_another_type: {
    label: "Name of Source",
    type: "string",
  },
  i94_number: {
    label: "I-94 Number",
    type: "string",
  },
  has_passport: {
    label: "Passport",
    type: "checkbox",
  },
  passport_number: {
    label: "Passport Number",
    type: "string",
  },
  has_visa: {
    label: "F-1 Visa or Status",
    type: "checkbox",
  },
  has_i94: {
    label: "I-94",
    type: "checkbox",
  },
  has_transer_from_i94: {
    label: "Transfer From",
    type: "checkbox",
  },
  transfer_from_status: {
    label: "Transfer From Status",
    type: "string",
  },
  prev_school_name: {
    label: "Previous School Name",
    type: "string",
  },
};

const i20Info = {
  has_i20: {
    label: "I-20",
    type: "checkbox",
  },
  sevis_number: {
    label: "SEVIS ID Number",
    type: "string",
  },
  i20_start_date: {
    label: "Start Date",
    type: "date",
  },
  i20_end_date: {
    label: "End Date",
    type: "date",
  },
  i20_status: {
    label: "I-20 Status",
    type: "string",
  },
  i20_shipping: {
    label: "Shipping",
    type: "string",
  },
  i20_tracking_number: {
    label: "Tracking Number",
    type: "string",
  },
  i20_shipping_date: {
    label: "Shipping Date",
    type: "date",
  },
};

function Profile() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { studentInfo } = useContext(AuthContext);

  const { message, isLoading, data, hasError, error, updateData } =
    useFetchApi(getI20InfoRequest);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getBasicProfileInfo = useCallback(() => {
    return Object.keys(studentInfo)
      .filter((key) => basicInformationKeys.hasOwnProperty(key))
      .map((key) => {
        return {
          value: studentInfo[key],
          label: basicInformationKeys[key].label,
          isCheckbox: basicInformationKeys[key]?.isCheckbox ?? false,
          type: basicInformationKeys[key]?.type ?? "string",
        };
      });
  }, [studentInfo]);

  const getHomeCountryInfo = useCallback(() => {
    return Object.keys(studentInfo)
      .filter((key) => homeCountryInfo.hasOwnProperty(key))
      .map((key) => {
        return {
          value: studentInfo[key],
          label: homeCountryInfo[key].label,
          isCheckbox: homeCountryInfo[key]?.isCheckbox ?? false,
          type: homeCountryInfo[key]?.type ?? "string",
        };
      });
  }, [studentInfo]);

  const getUsAddressInfo = useCallback(() => {
    return Object.keys(studentInfo)
      .filter((key) => usAddressInfo.hasOwnProperty(key))
      .map((key) => {
        return {
          value: studentInfo[key],
          label: usAddressInfo[key].label,
          isCheckbox: usAddressInfo[key].isCheckbox,
          type: usAddressInfo[key]?.type ?? "string",
        };
      });
  }, [studentInfo]);

  const getBankStatementInfo = useCallback(() => {
    if (typeof data !== "object") {
      return [];
    }
    return Object.keys(data)
      .filter((key) => {
        let hasProperty = bankStatementInfo.hasOwnProperty(key);
        if (!hasProperty) {
          return false;
        }
        if (key === "has_transer_from_i94") {
          return false;
        }
        if (
          key === "transfer_from_status" &&
          (data?.has_transer_from_i94 ?? false)
        ) {
          return true;
        }
        return hasProperty;
      })
      .map((key) => {
        return {
          value: data[key],
          label: bankStatementInfo[key].label,
          isCheckbox: bankStatementInfo[key].isCheckbox,
          type: bankStatementInfo[key]?.type ?? "string",
        };
      });
  }, [data]);

  const getI20Info = useCallback(() => {
    if (typeof data !== "object") {
      return [];
    }
    return Object.keys(data)
      .filter((key) => i20Info.hasOwnProperty(key))
      .map((key) => {
        return {
          value: data[key],
          label: i20Info[key].label,
          isCheckbox: i20Info[key].isCheckbox,
          type: i20Info[key]?.type ?? "string",
        };
      });
  }, [data]);

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-3rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <TabContext value={value + ""}>
                <CardHeader
                  classes={{
                    root: classes.cardHeaderRoot,
                    content: classes.cardHeaderContent,
                  }}
                  title={
                    <>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="secondary"
                        textColor="secondary"
                      >
                        <Tab
                          icon={
                            <Box
                              component={AccountBoxIcon}
                              marginRight=".1rem"
                            />
                          }
                          label="General"
                        />
                        <Tab
                          icon={
                            <Box
                              component={DescriptionIcon}
                              marginRight=".1rem"
                            />
                          }
                          label="I-20 Status"
                        />
                        <Tab
                          icon={
                            <Box
                              component={FormatListBulletedIcon}
                              marginRight=".1rem"
                            />
                          }
                          label="Attendance"
                        />
                        <Tab
                          icon={
                            <Box component={GradeIcon} marginRight=".1rem" />
                          }
                          label="Grade"
                        />
                      </Tabs>
                    </>
                  }
                ></CardHeader>
                <CardContent>
                  <TabPanel value="0">
                    <KeyValueGridDataTable values={getBasicProfileInfo()} />
                    <KeyValueGridDataTable
                      title="Home Country Address"
                      values={getHomeCountryInfo()}
                    />
                    <KeyValueGridDataTable
                      title="US Address"
                      values={getUsAddressInfo()}
                    />
                  </TabPanel>
                  <TabPanel value="1">
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {hasError ? (
                          <Typography variant="h3">
                            Could not load I-20 data, try again later
                          </Typography>
                        ) : (
                          <>
                            <KeyValueGridDataTable
                              key="bankStatement"
                              title="Bank Statement"
                              values={getBankStatementInfo()}
                            />
                            <KeyValueGridDataTable
                              key="i20Info"
                              title="I-20"
                              values={getI20Info()}
                            />
                          </>
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value="2">
                    <AttendanceTable />
                  </TabPanel>
                  <TabPanel value="3">
                    <GradesTable />
                  </TabPanel>
                </CardContent>
              </TabContext>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            xl={4}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.order1 + " " + classes.marginBottomXl0 }}
          >
            <Card classes={{ root: classes.cardRoot }}>
              <Box component={Grid} container justifyContent="center">
                <Grid item xs={12} lg={3}>
                  <Box position="relative">
                    <Box
                      component={Avatar}
                      src={
                        studentInfo?.photo_url
                          ? `https://admin.languagesystems.edu/uploads/students/profile/${studentInfo?.photo_url}`
                          : ""
                      }
                      //TODO student placeholder image
                      alt="..."
                      position="absolute"
                      left="50%"
                      boxShadow={boxShadows.boxShadow + "!important"}
                      className={classes.profileImage}
                    >
                      {studentInfo.first_name.charAt(0) +
                        studentInfo.last_name.charAt(0)}
                    </Box>
                  </Box>
                </Grid>
              </Box>
              <Box
                component={CardHeader}
                border="0!important"
                textAlign="center"
                paddingBottom="0!important"
                paddingTop="8rem!important"
                classes={{ root: classes.cardHeaderRootProfile }}
              ></Box>
              <Box
                component={CardContent}
                classes={{ root: classes.ptMd4 }}
                paddingTop="0!important"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      padding="1rem 0"
                      justifyContent="center"
                      display="flex"
                      className={classes.mtMd5}
                    ></Box>
                  </Grid>
                </Grid>
                <Box textAlign="center">
                  <Typography variant="h3">
                    {`${studentInfo?.first_name ?? ""} ${
                      studentInfo?.last_name ?? ""
                    }`}
                  </Typography>
                  <Box
                    component={Typography}
                    variant="h5"
                    fontWeight="300!important"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      component={LocationOn}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    ></Box>
                    {`${studentInfo?.us_city ?? ""}, ${
                      studentInfo?.us_state ?? ""
                    }`}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Profile;
