import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/dashboard.js";
import AnnouncementsCarousel from "./components/AnnouncementsCarousel";
import { getAnnouncementsForStudents } from "services/api/Repos/Announcements";

const useStyles = makeStyles(componentStyles);

function Dashboard() {
  const classes = useStyles();
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const announcementsResponse = await getAnnouncementsForStudents();
        if (isMounted && !announcementsResponse.error) {
          setAnnouncements(announcementsResponse.data);
        }
      } catch (error) {}
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={9}>
            <AnnouncementsCarousel announcements={announcements} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;
