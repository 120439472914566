import { axios } from "../Config";

export const login = (email, password, remember_me) => {
  const body = { email, password, remember_me };
  return axios.post("auth/login", body);
};

export const refreshToken = () => {
  return axios.post("auth/refresh");
};

export const logout = () => {
  return axios.post("auth/logout");
};
