import { createContext } from "react";

const AuthContext = createContext({
  isLoggedIn: false,
  student_id: null,
  location_id: null,
  token: null,
  loadUser: () => {},
  setStudentInfo: () => {},
  logout: () => {},
  isLoading: true,
  studentInfo: {
    id: "",
    location_id: "",
    first_name: "N/A",
    last_name: "N/A",
    middle_name: "N/A",
    email: "N/A",
    file_number: "N/A",
    photo_url: "",
    gender: "N/A",
    birth_date: "N/A",
    type: "N/A",
    location: "N/A",
    is_booked_by_agent: false,
    read_orientation: false,
    nationality: "N/A",
    county_of_birth: "N/A",
    city_of_birth: "N/A",
    us_city: "N/A",
    us_state: "N/A",
    us_address: "N/A",
    us_zip_code: "N/A",
    us_phone_number: "N/A",
    us_second_phone_number: "N/A",
    f_city: "N/A",
    f_state: "N/A",
    f_address: "N/A",
    f_country: "N/A",
    f_zip_code: "N/A",
    f_phone_number: "N/A",
    f_second_phone_number: "N/A",
  },
});

export default AuthContext;
