import React from "react";
import {
  CardContent,
  CardHeader,
  Container,
  Card,
  TableContainer,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useFetchApi } from "hooks";
import { getAll as getAllReceipts } from "services/api/Repos/Receipts";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import componentStyles from "assets/theme/views/admin/tables.js";
import ReceiptTable from "components/Shared/ReceiptTable";

const useStyles = makeStyles(componentStyles);

const PaidList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { isLoading, data } = useFetchApi(getAllReceipts);

  return (
    <Container maxWidth={false} component={Box} marginTop="3rem">
      <Card
        classes={{
          root: classes.cardRoot + " " + classes.cardRootSecondary,
        }}
      >
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
            content: classes.cardHeaderContent,
          }}
          title="Receipts"
          subheader="Your receipts"
        ></CardHeader>
        <CardContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              {Array.isArray(data) &&
                data.map((receipt, index) => (
                  <ReceiptTable
                    receipt={receipt}
                    key={index}
                    anchorId={window.location.href.slice(
                      window.location.href.indexOf("#") + 1
                    )}
                  />
                ))}
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default PaidList;
