import { axios } from "services/api/Config";

export const getUnreadMessages = () => {
  return axios.get("messages/unread");
};

export const getAllMessages = () => {
  return axios.get("messages/all");
};

export const sendMessage = (message) => {
  return axios.post("messages", {
    message,
  });
};

export const readMessages = () => {
  return axios.post("messages/read");
};
