import React, { useCallback } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { DatePicker } from "@material-ui/pickers";
import { createOrder, processPayment } from "services/api/Repos/Payments";
import { useSnackbar } from "notistack";

const scheduleOptions = [
  {
    value: "morning",
    label: "Morning",
  },
  {
    value: "afternoon",
    label: "Afternoon",
  },
  {
    value: "evening",
    label: "Evening",
  },
];

const PaypalStep = ({
  programSale,
  startDate,
  endDate,
  selectedPrice,
  handleBackButton,
  handleSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const getProgramSaleOptions = useCallback(() => {
    if (Array.isArray(programSale)) {
      return programSale.map((element) => {
        return {
          value: element.program_id,
          label: element.program_name,
        };
      });
    }
    return [];
  }, [programSale]);

  const handleCreateOrder = async (data, actions) => {
    var resultServer = await createOrder(
      startDate,
      endDate,
      selectedPrice.price_id
    );
    if (resultServer.error) {
      var response = resultServer.data;
      if (response && response.message) {
        throw new Error(response.message);
      } else {
        throw new Error(
          "An error has ocorred when trying to create the order, nothing was done"
        );
      }
    }
    return resultServer.data.order_id;
  };

  const handleError = (error) => {
    enqueueSnackbar(error.message, { variant: "error" });
    return handleBackButton();
  };

  const handleOnApprove = async (data, actions) => {
    var authorization = await actions.order.authorize();
    var authorizationID =
      authorization.purchase_units[0].payments.authorizations[0].id;
    var axiosResponse = await processPayment(data.orderID, authorizationID);
    if (axiosResponse.status === 201) {
      // Show a success message
      enqueueSnackbar(axiosResponse.message, { variant: "success" });
      return handleSuccess();
    }

    if (axiosResponse.status === 422) {
      // There are some errors with the payment form before, tell user and get back to form
      throw new Error(axiosResponse.message);
    }

    var orderData = axiosResponse.data.orderData;
    // Three cases to handle:
    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //   (2) Other non-recoverable errors -> Show a failure message
    //   (3) Successful transaction -> Show confirmation or thank you

    // This example reads a v2/checkout/orders capture response, propagated from the server
    // You could use a different API or structure for your 'orderData'
    var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

    if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
      enqueueSnackbar(
        "Error completing payment with paypal, restart the payment",
        { variant: "warning" }
      );
      return actions.restart(); // Recoverable state, per:
      // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
    }

    if (errorDetail) {
      var msg = "Sorry, your transaction could not be processed.";
      if (errorDetail.description) msg += "\n\n" + errorDetail.description;
      if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
      throw new Error(msg); // Show a failure message
    }
    if (axiosResponse.status < 200 || axiosResponse.status >= 300) {
      throw new Error(
        "Something went wrong, please try again later.: " +
          axiosResponse.message
      );
    }
  };

  return (
    <Grid container spacing={2} alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                name="program"
                id="program"
                label="Program"
                disabled
                value={
                  getProgramSaleOptions().find(
                    (option) => option.value === selectedPrice.program_id
                  ).label
                }
                placeholder="Program"
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                name="schedule"
                id="schedule"
                value={
                  scheduleOptions.find(
                    (option) => option.value === selectedPrice.schedule
                  ).label
                }
                disabled
                label="Schedule"
                placeholder="Schedule"
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <DatePicker
                InputLabelProps={{ shrink: true }}
                orientation="landscape"
                openTo="date"
                id="date_initial"
                disabled
                value={startDate}
                name="date_initial"
                label="Start date"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <DatePicker
                InputLabelProps={{ shrink: true }}
                orientation="landscape"
                openTo="date"
                id="date_final"
                name="date_final"
                value={endDate}
                disabled
                label="End date"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Number of Weeks"
                value={selectedPrice.number_of_weeks}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Price credit card"
                value={selectedPrice.price_credit_card}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="1.5rem"
          marginBottom="1.5rem"
        >
          <PayPalButtons
            style={{
              shape: "pill",
              color: "silver",
              label: "pay",
            }}
            createOrder={handleCreateOrder}
            onApprove={handleOnApprove}
            onError={handleError}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="1.5rem"
          marginBottom="1.5rem"
        >
          <Button
            onClick={handleBackButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Back to prices
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaypalStep;
