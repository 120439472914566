import { axios } from "services/api/Config";

export const getUserEmail = () => {
  return axios.get("auth/get-user-email");
};

export const getUserAuthHash = () => {
  return axios.get("auth/get-user-hmac");
};

export const getStudentInfo = () => {
  return axios.get("auth/me");
};

export const updateStudentInfo = (newData) => {
  return axios.put("student/update", newData);
};

export const getI20Info = () => {
  return axios.get("student/i20");
};

export const updatePassword = ({
  old_password,
  password,
  password_confirmation,
}) => {
  return axios.put("student/change_password", {
    old_password,
    password,
    password_confirmation,
  });
};
