import assignedClassReducer from "./AssignedClass/reducer";
import messageReducer from "./Messages/reducer";

//Include all the reducer to combine and provide to configure store.

const reducers = {
  assignedClassReducer,
  messageReducer,
};

export default reducers;
