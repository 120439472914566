import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/components/card-stats.js";
import { Link, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

function CalendarLabels() {
  const classes = useStyles();
  const theme = useTheme();

  const calendarLabels = [
    {
      size: "1rem",
      shape: "square",
      label: "Paid dates",
      helperText: "",
      color: theme.palette.success.main,
    },
    {
      size: "1rem",
      shape: "square",
      label: "Test date",
      helperText: "",
      color: theme.palette.info.main,
    },
    {
      size: "1.5rem",
      shape: "square",
      label: "Class dates",
      helperText: "",
      color: "rgb(202, 211, 228)",
    },
    {
      size: "0.7rem",
      shape: "circle",
      label: "Absent attendance on this date",
      helperText: "",
      color: theme.palette.error.main,
    },
    {
      size: "0.7rem",
      shape: "circle",
      label: "Present attendance on this date",
      helperText: "",
      color: theme.palette.info.main,
    },
    {
      size: "0.7rem",
      shape: "circle",
      label: "Tardy attendance on this date",
      helperText: "",
      color: theme.palette.common.white,
    },
    {
      size: "0.7rem",
      shape: "circle",
      label: "Holiday",
      helperText: "",
      color: theme.palette.success.main,
    },
    {
      size: "0.7rem",
      shape: "circle",
      label: "Gone attendance on this date",
      helperText: "",
      color: theme.palette.warning.main,
    },
  ];

  return (
    <Card classes={{ root: classes.cardRoot }} elevation={0}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container component={Box} justifyContent="space-between">
          {calendarLabels.map((label, index) => (
            <Grid item xs={12} xl={6} key={index}>
              <Box paddingTop=".35rem" paddingBottom=".35rem">
                <Box
                  marginRight="10px"
                  width={label.size}
                  height={label.size}
                  borderRadius={label.shape === "square" ? "0" : "50%"}
                  display="inline-block"
                  className={classes.verticalAlignMiddle}
                  style={{
                    backgroundColor: label.color,
                    border: "1px solid gray",
                  }}
                ></Box>
                {label.helperText !== "" ? (
                  <Tooltip arrow title={label.helperText}>
                    <Link underline="always" color="inherit">
                      {label.label}
                    </Link>
                  </Tooltip>
                ) : (
                  <span color="inherit">{label.label}</span>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CalendarLabels;
