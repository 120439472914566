import React, { useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import YoutubeComponent from "../OrientationVideo/components/YoutubeComponent";
import { AuthContext } from "context";
import { Button, Typography } from "@material-ui/core";
import { updateStudentInfo } from "services/api/Repos/Student";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(componentStyles);

function Orientation() {
  const classes = useStyles();
  const { studentInfo, setStudentInfo } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const { data } = await updateStudentInfo({ read_orientation: 1 });
      setStudentInfo(data);
      setIsLoading(false);
      <Redirect to="/dashboard" />;
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} xl={9}>
            <YoutubeComponent video_id="KHEQTheKKKU" />
          </Grid>
          <Grid item xs={12} xl={9}>
            <a
              href="https://www.languagesystems.edu/public/student-catalog.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="primary"
                variant="contained"
                disabled={isLoading}
                style={{ marginTop: "1rem", marginRight: "1.5rem" }}
              >
                Student Catalog
              </Button>
            </a>

            {studentInfo.read_orientation !== 1 ? (
              <Button
                onClick={handleClick}
                color="primary"
                variant="contained"
                disabled={isLoading}
                style={{ marginTop: "1rem" }}
              >
                {!!isLoading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : (
                  "I watched the video"
                )}
              </Button>
            ) : (
              <>
                <Typography variant="h3" style={{ marginTop: "1rem" }}>
                  Thank you for watching the orientation video!
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Orientation;
