import React from "react";
import { getAllPrices } from "services/api/Repos/Payments";

export function useProgramPrices() {
  const [allPrices, setAllPrices] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const programPriceResonse = await getAllPrices();
        if (isMounted && !programPriceResonse.error) {
          setAllPrices(programPriceResonse.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return allPrices;
}
