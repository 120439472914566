import actions from "./actions";

const initialState = {
  messages: [],
  unreadMessages: [],
  status: "idle", //'loading' | 'error' | 'success'
  message: "", //Error message
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_MESSAGES:
      return { ...state, status: "loading" };
    case actions.GET_MESSAGES_SUCCESS: {
      let unreadMessages = (action.payload?.data ?? []).filter(
        (message) => !(message?.seen ?? false)
      );
      return {
        ...state,
        messages: action.payload?.data ?? [],
        unreadMessages: unreadMessages,
        status: "success",
      };
    }
    case actions.GET_CLASSES_FAILURE:
      return {
        ...state,
        status: "error",
        message: action.payload?.message ?? "Error trying to load messages",
      };
    case actions.READ_MESSAGES:
      return state;
    case actions.SEND_MESSAGE:
      return {
        ...state,
        status: "loading",
      };
    case actions.SEND_MESSAGE_SUCCESS: {
      let newMessages = state.messages;
      if (action?.payload?.data !== null) {
        newMessages.push(action.payload.data);
      }
      return {
        ...state,
        messages: newMessages,
        message: "Message sent successfully",
        status: "success",
      };
    }
    case actions.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        status: "error",
        message: "Error trying to send message, please try again later",
      };
    default:
      return state;
  }
}

export default Reducer;
