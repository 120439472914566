import React, { useCallback, useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { default as CalendarComponent } from "components/Calendars/Calendar";

// core components
import CalendarHeader from "components/Headers/CalendarHeader.js";

import componentStyles from "assets/theme/views/admin/calendar.js";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextField from "@material-ui/core/TextField";
import { DateTime } from "luxon";
import { printDateFriendly } from "helpers/date";

import { useCalendarEvents } from "hooks";
import {
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  Avatar,
} from "@material-ui/core";
import { attendanceNames } from "variables/constants";
import { capitalize } from "helpers/misc";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(componentStyles);

const footerToolbar = {
  start: "prev,today,next",
  end: "dayGridMonth,dayGridWeek,listMonth",
};

const Calendar = () => {
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    eventType: "",
    backgroundColor: "",
    extendedProps: {},
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [headerToolbar, setHeaderToolbar] = useState({
    start: "",
    center: "title",
    end: "",
  });
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const events = useCalendarEvents();

  useEffect(() => {
    if (isMdUp) {
      setHeaderToolbar({
        start: "prev,today,next",
        center: "title",
        end: "dayGridMonth,dayGridWeek,listMonth",
      });
    } else {
      setHeaderToolbar({
        start: "",
        center: "title",
        end: "",
      });
    }
  }, [isMdUp]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleEventClick = (eventData) => {
    if (eventData.event.extendedProps.eventType === "") {
      return;
    }
    setSelectedEvent({
      title: eventData.event?.title ?? "Unkown event",
      description: eventData.event?.description ?? "",
      start: eventData.event?.startStr ?? "Invalid Start Date",
      end: eventData.event?.endStr ?? "Invalid End Date",
      eventType: eventData.event.extendedProps.eventType,
      backgroundColor:
        eventData.event?.backgroundColor ?? theme.palette.common.white,
      extendedProps: eventData.event?.extendedProps ?? {},
    });
    setDialogOpen(true);
  };

  const getInitials = useCallback((value) => {
    const splitedValue = value.split(" ");
    var initials = splitedValue.map((element) => element.charAt(0));
    return initials.join(" ");
  }, []);

  function eventDidMount(info) {
    if (info.event.extendedProps.eventType !== "attendance") {
      info.el.style.backgroundColor = info.event.backgroundColor;
      var dotEl = info.el.getElementsByClassName("fc-list-event-dot")[0];
      if (dotEl) {
        dotEl.remove();
      }
    }
  }

  const renderEventContent = useCallback(
    (eventInfo) => {
      return (
        <>
          {eventInfo.event._def.extendedProps.eventType === "attendance" && (
            <>
              {!eventInfo.view.type.includes("list") && (
                <div
                  className="fc-daygrid-event-dot"
                  style={{
                    borderColor: eventInfo.event.backgroundColor,
                    color: eventInfo.event.backgroundColor,
                  }}
                ></div>
              )}
              <i>
                <b>
                  {isMdUp || eventInfo.view.type.includes("list")
                    ? attendanceNames[eventInfo.event.title]
                    : eventInfo.event.title}
                </b>
              </i>
              {eventInfo.event.extendedProps?.pending_payment && (
                <b>Pending Payment</b>
              )}
            </>
          )}
          {eventInfo.event.extendedProps.eventType !== "attendance" && (
            <span
              style={{
                color: theme.palette.getContrastText(
                  eventInfo.event.backgroundColor
                ),
              }}
            >
              <b>
                {isMdUp || eventInfo.view.type.includes("list")
                  ? eventInfo.event.title
                  : getInitials(eventInfo.event.title)}
              </b>
            </span>
          )}
        </>
      );
    },
    [isMdUp, theme.palette, getInitials]
  );

  const renderDialogContent = () => {
    switch (selectedEvent.eventType) {
      case "test_prep": {
        return (
          <>
            <DialogTitle id="form-dialog-title">Test Preparation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {selectedEvent.extendedProps?.subject?.subject ?? "Unknown"}{" "}
                test preparation
              </DialogContentText>
              <TextField
                id="date"
                label="Date"
                type="date"
                disabled
                value={
                  selectedEvent.extendedProps?.date_string ??
                  selectedEvent.start
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      }
      case "paid_days": {
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Paid Days for {selectedEvent.title} program
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Paid: {selectedEvent.title} (
                {capitalize(selectedEvent.extendedProps.schedule?.schedule) ??
                  ""}
                )
              </DialogContentText>
              <TextField
                id="date"
                label="Start Date"
                type="date"
                disabled
                value={selectedEvent.extendedProps.start_date_string}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="End Date"
                type="date"
                disabled
                value={selectedEvent.extendedProps.end_date_string}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      }
      case "attendance": {
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Attendances for date:{" "}
              {DateTime.fromSQL(selectedEvent.start).toLocaleString()}
            </DialogTitle>
            <DialogContent>
              {selectedEvent.extendedProps.pending_payment && (
                <DialogContentText>
                  <span style={{ color: theme.palette.error.main }}>
                    You have pending payments for this attendances
                  </span>
                </DialogContentText>
              )}
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={selectedEvent.extendedProps.values.length}
                    >
                      Attendance for{" "}
                      {selectedEvent.extendedProps?.class?.level.level_name ??
                        "N/A"}
                      <br></br>
                      Assigned at:{" "}
                      <Box
                        component="span"
                        color={selectedEvent.backgroundColor}
                      >
                        {printDateFriendly(
                          selectedEvent.extendedProps?.class?.assign_date
                        )}
                      </Box>
                      <br></br>
                      Total Attendance percent:{" "}
                      <Box
                        component="span"
                        color={selectedEvent.backgroundColor}
                      >
                        {selectedEvent.extendedProps?.class?.attendance
                          .attendance_percent ?? "N/A"}
                        %
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={selectedEvent.extendedProps.values.length}
                    >
                      {DateTime.fromSQL(selectedEvent.start).toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {selectedEvent.extendedProps.values.map((value, index) => (
                      <TableCell key={index} align="center">
                        {value.number}
                        <br></br>
                        {attendanceNames[value.attendance]}
                        <br></br>
                        <Avatar
                          style={{
                            color: theme.palette.getContrastText(
                              selectedEvent.backgroundColor
                            ),
                            backgroundColor: selectedEvent.backgroundColor,
                          }}
                        >
                          {value.attendance}
                        </Avatar>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      }
      case "session_background": {
        return (
          <>
            <DialogTitle id="form-dialog-title">
              {selectedEvent.extendedProps?.start_date
                ? DateTime.fromSQL(selectedEvent.extendedProps?.start_date).year
                : ""}{" "}
              {selectedEvent.extendedProps.term_name}, Session{" "}
              {selectedEvent.extendedProps.session_sufix}
            </DialogTitle>
            <DialogContent>
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      {selectedEvent.extendedProps?.start_date
                        ? DateTime.fromSQL(
                          selectedEvent.extendedProps?.start_date
                        ).year
                        : ""}{" "}
                      {selectedEvent.extendedProps.term_name}{" "}
                      {selectedEvent.extendedProps.session_sufix}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      Starts at:{" "}
                      {DateTime.fromSQL(
                        selectedEvent.extendedProps.start_date
                      ).toLocaleString()}
                    </TableCell>
                    <TableCell align="center">
                      Ends at:{" "}
                      {DateTime.fromSQL(
                        selectedEvent.extendedProps.end_date
                      ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      }
      default: {
        return (
          <>
            <DialogTitle id="form-dialog-title">
              {selectedEvent.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {selectedEvent?.description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      }
    }
  };

  return (
    <>
      <CalendarHeader />
      {/* Page content */}
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {renderDialogContent()}
      </Dialog>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Calendar"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          <CalendarComponent
            events={events}
            handleEventClick={handleEventClick}
            renderEventContent={renderEventContent}
            footerToolbar={footerToolbar}
            headerToolbar={headerToolbar}
            eventDidMount={eventDidMount}
          />
        </Card>
      </Container>
    </>
  );
};

export default Calendar;
