const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
  gridRoot: {
    flexGrow: 1,
  },
  video_wrapper: {
    position: "relative",
    paddingBottom: "56.25%",
  },
  iframe: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
});

export default componentStyles;
