import React from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

const Calendar = ({
  events,
  handleEventClick,
  renderEventContent,
  headerToolbar,
  footerToolbar,
  eventDidMount,
}) => {
  return (
    <>
      <div style={{ padding: "1rem" }}>
        <FullCalendar
          plugins={[dayGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          events={events}
          dayMaxEvents={2}
          displayEventTime={false}
          eventContent={renderEventContent}
          eventDidMount={eventDidMount}
          headerToolbar={headerToolbar}
          footerToolbar={footerToolbar}
          eventClick={handleEventClick}
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5],
          }}
        />
      </div>
    </>
  );
};

export default Calendar;
