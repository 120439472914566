import React, { useCallback, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/Messages/actions";

import { MessageLeft, MessageRight } from "./Message";
import { MessageInput } from "./MessageInput.js";
import { printDateTimeFriendly } from "helpers/date";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      padding: theme.spacing(2),
    },
    paper2: {
      width: "100%",
      maxWidth: "100%",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      overflowX: "hidden",
      height: "80vh",
      padding: theme.spacing(1),
      scrollBehavior: "smooth",
    },
  })
);

export default function ChatComponent() {
  const classes = useStyles();
  const { messages, status, message, unreadMessages } = useSelector(
    (state) => state.messageReducer
  );
  const dispatch = useDispatch();

  const getMessages = () => {
    dispatch({
      type: actions.GET_MESSAGES,
      payload: {},
    });
  };

  const sendMessage = (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    dispatch({
      type: actions.SEND_MESSAGE,
      payload: values,
    });
    resetForm({});
    return;
  };

  const readMessages = () => {
    dispatch({ type: actions.READ_MESSAGES, payload: {} });
  };

  useEffect(() => {
    if (status === "idle") {
      return getMessages();
    }
  }, [status]);

  const handleScroll = useCallback(
    (node) => {
      if (node !== null) {
        node.scrollTop = node.scrollHeight;
      }
    },
    [messages]
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={2}>
        {unreadMessages.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={readMessages}
          >
            Mark as readed
          </Button>
        )}
        <Paper id="style-1" className={classes.messagesBody} ref={handleScroll}>
          {(messages ?? []).length === 0 ? (
            <ListItemText
              align="center"
              primary="No messages sent yet"
            ></ListItemText>
          ) : (
            <>
              {(messages ?? []).map((message, index) => (
                <React.Fragment key={index}>
                  {(message?.message ?? false) &&
                  !(message?.response_message ?? false) ? (
                    <MessageRight
                      message={message?.message ?? ""}
                      timestamp={printDateTimeFriendly(message?.date ?? "")}
                      photoURL=""
                      displayName=""
                      avatarDisp={false}
                    />
                  ) : (
                    !(message?.message ?? false) &&
                    (message?.response_message ?? false) && (
                      <MessageLeft
                        message={message?.response_message ?? ""}
                        timestamp={printDateTimeFriendly(message?.date ?? "")}
                        photoURL=""
                        displayName=""
                        avatarDisp={false}
                      />
                    )
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </Paper>
        <MessageInput onSubmit={sendMessage} />
      </Paper>
    </div>
  );
}
