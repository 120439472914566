import { axios } from "services/api/Config";
import { DateTime } from "luxon";

export const createOrder = (start_date, end_date, price_id) => {
  if (DateTime.isDateTime(start_date)) {
    start_date = start_date.toSQLDate();
  }
  if (DateTime.isDateTime(end_date)) {
    end_date = end_date.toSQLDate();
  }
  return axios.post("payments/create", {
    start_date,
    end_date,
    price_id,
  });
};

export const processPayment = (order_id, authorization_id) => {
  return axios.post("payments/process", {
    order_id,
    authorization_id,
  });
};

export const getAllPrices = () => {
  return axios.get("payments/prices");
};

export const getPriceByProgram = (program_id) => {
  return axios.get("payments/prices/program", null, {
    params: {
      program_id,
    },
  });
};
