const componentStyles = (theme) => ({
  wrapperBox: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem",
    },
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  overlayBox: {
    transition: "all .15s ease",
    opacity: ".8",
    background:
      "linear-gradient(87deg," + theme.palette.info.main + ",#be0061)",
  },
  containerRoot: {
    zIndex: 1,
    paddingLeft: "39px",
    paddingRight: "39px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
  typographyRootH1: {
    color: theme.palette.white.main,
    fontSize: "2.75rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  buttonRoot: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
});

export default componentStyles;
