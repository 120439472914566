export const locations = {
  1: "Northeast LA",
  2: "Orange County",
  3: "Downtown LA",
  4: "South Bay LA",
};

export const attendanceNames = {
  A: "Absent",
  P: "Present",
  T: "Tardy",
  L: "Leave of Absence",
  H: "Holiday",
  G: "Gone/Noncount",
};

export function getAttendanceName(attendanceLetter) {
  switch (attendanceLetter) {
    case "A":
      return "Absent";
    case "P":
      return "Present";
    case "T":
      return "Tardy";
    case "L":
      return "Leave of Absence";
    case "H":
      return "Holiday";
    case "G":
      return "Gone/Noncount";
    default:
      return "Attendance";
  }
}
