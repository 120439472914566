import { axios } from "services/api/Config";

export const getBySession = (session_id) => {
  return axios.get("attendance_dates/session", null, {
    params: {
      session_id,
    },
  });
};

export const getAllPaginated = () => {
  return axios.get("attendance_dates/future");
};
