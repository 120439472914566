import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

function UploadedFiles({ documents }) {
  return (
    <>
      <Box component={"paper"}>
        {(documents ?? []).map((document) => (
          <>
            <a
              download={document.file_name}
              href={document.url}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body1"
                style={{
                  wordWrap: "break-word",
                  marginLeft: "1.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {document.file_name}
                <GetAppIcon style={{ marginLeft: "0.25rem" }} />
              </Typography>
            </a>
          </>
        ))}
      </Box>
    </>
  );
}

Document.propTypes = {
  title: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      file_name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default UploadedFiles;
