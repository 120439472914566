import React, { useCallback } from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
} from "@material-ui/core";
import { attendanceNames } from "variables/constants";
import componentStyles from "assets/theme/components/assigned-class-attendances-table";
import PropTypes from "prop-types";
import { printDateFriendly } from "helpers/date";
import { getAttendanceColorProperty, getPropByString } from "helpers/misc";
import AvatarGroupWithTooltip from "./AvatarGroupWithTooltip";

const useStyles = makeStyles(componentStyles);

function AssignedClassAttendance({ assignedClass, color }) {
  const classes = useStyles();
  const theme = useTheme();

  const getAvatarsValues = useCallback(
    (values) => {
      if (!Array.isArray(values)) {
        return [];
      }
      return values.map((value) => {
        const avatarBackgroundColor = getPropByString(
          theme.palette,
          getAttendanceColorProperty(value.attendance)
        );
        return {
          tooltipText: attendanceNames[value.attendance],
          avatarTextColor: theme.palette.getContrastText(avatarBackgroundColor),
          avatarBackgroundColor: avatarBackgroundColor,
          avatarFallbackText: value.attendance,
        };
      });
    },
    [theme.palette]
  );

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Typography align="center">
            <b>Attendance for</b>{" "}
            <Box
              component="span"
              color={(color ?? "") !== "" ? color : theme.palette.info.main}
            >
              {assignedClass?.level.level_name ?? "N/A"} |{" "}
              {assignedClass?.session.session_name ?? "N/A"}
            </Box>
            <br />
            <b>Assigned at:</b>{" "}
            <Box
              component="span"
              color={(color ?? "") !== "" ? color : theme.palette.info.main}
            >
              {printDateFriendly(assignedClass?.assign_date)}
            </Box>
            <br />
            <b>Total Attendance percent:</b>{" "}
            <Box
              component="span"
              color={(color ?? "") !== "" ? color : theme.palette.info.main}
            >
              {assignedClass?.attendance?.attendance_percent ?? "N/A"}%
            </Box>
          </Typography>
        }
        titleTypographyProps={{
          component: Box,
          marginBottom: "0!important",
          variant: "h3",
        }}
      ></CardHeader>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <TableContainer style={{ maxHeight: 200 }}>
          <Table>
            <TableBody>
              {(assignedClass?.attendance?.attendances ?? []).length === 0 ? (
                <TableRow>
                  <TableCell align="center">
                    <Typography align="center">
                      No Attendances data found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                (assignedClass?.attendance?.attendances ?? []).map(
                  (attendance, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <Typography align="center">
                          {printDateFriendly(attendance?.date ?? "")}
                        </Typography>
                        <br />
                        {(attendance?.values ?? []).length > 0 ? (
                          <AvatarGroupWithTooltip
                            avatars={getAvatarsValues(attendance?.values ?? [])}
                          />
                        ) : (
                          <Typography align="center">
                            No Attendance data found
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
              {(assignedClass?.attendance?.makeup ?? []).length > 0 &&
                (assignedClass?.attendance?.makeup ?? []).map(
                  (attendance, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <Typography align="center">
                          Makeup {index + 1}
                        </Typography>
                        <br />
                        {(attendance?.values ?? []).length > 0 ? (
                          <AvatarGroupWithTooltip
                            avatars={getAvatarsValues(attendance?.values ?? [])}
                          />
                        ) : (
                          <Typography align="center">
                            No Attendance data found
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

AssignedClassAttendance.propTypes = {
  assignedClass: PropTypes.shape({
    attendance: PropTypes.shape({
      attendances: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          is_holyday: PropTypes.number,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              attendance: PropTypes.string,
            })
          ),
        })
      ),
      attendance_percent: PropTypes.string,
      attendance_date: PropTypes.string,
      pending_payment: PropTypes.number,
    }),
    level: PropTypes.shape({
      level_name: PropTypes.string,
    }),
    session: PropTypes.shape({
      session_name: PropTypes.string,
      start_date: PropTypes.string,
      year: PropTypes.string,
    }),
    assign_date: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
};

export default AssignedClassAttendance;
