import React, { useContext, useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import Edit from "@material-ui/icons/Edit";

// core components
import componentStyles from "assets/theme/components/user-header.js";
import badgeStyles from "assets/theme/components/badge.js";

import { AuthContext } from "context";
import { locations } from "variables/constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem,
} from "@material-ui/core";
import StudentDocuments from "components/Shared/StudentDocuments";
import UpdateProfileInfo from "components/Shared/UpdateProfileInfo";
import actions from "redux/Messages/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);

const UserHeader = () => {
  const classes = useStyles();
  const classesBadge = useStylesBadge();
  const theme = useTheme();
  const {
    studentInfo,
    location_id,
    isLoading: isLoadingAuthContext,
  } = useContext(AuthContext);
  const { unreadMessages, status } = useSelector(
    (state) => state.messageReducer
  );
  const dispatch = useDispatch();

  const getMessages = () => {
    dispatch({
      type: actions.GET_MESSAGES,
      payload: {},
    });
  };

  useEffect(() => {
    if (status === "loading") {
      return;
    } else if (status === "idle") {
      return getMessages();
    }
  }, [status]);

  const [dialogType, setDialogType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const errorBadgeClasses = {
    badge:
      classesBadge.badgeCircle +
      " " +
      classesBadge.badgeBorderWhite +
      " " +
      classesBadge.badgeSizeMd,
  };

  const handleCloseDialog = () => {
    if (!isLoadingAuthContext) {
      setDialogOpen(false);
    }
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleClickPrintReportsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePrintReport = (report_type) => {
    switch (report_type) {
      case ('attendance'): {
        window.open(`${process.env.REACT_APP_IOS_BASE_URL}/reports_individual/student_cumulative_attendance.php`,'win2','status=no,toolbar=no,scrollbars=yes,titlebar=no,menubar=no,resizable=yes,width=900,height=700,directories=no,location=no');
        break;
      }
      case ('grades'): {
        window.open(`${process.env.REACT_APP_IOS_BASE_URL}/reports_individual/student_cumulative_progress.php`,'win2','status=no,toolbar=no,scrollbars=yes,titlebar=no,menubar=no,resizable=yes,width=900,height=700,directories=no,location=no');
        break;
      }
      default: {
        break;
      }
    }
    handleClosePrintReportsMenu();
  }

  const handleClosePrintReportsMenu = () => {
    setAnchorEl(null);
  };

  const renderDialogOpen = () => {
    switch (dialogType) {
      case "edit-profile": {
        return (
          <>
            <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
            <DialogContent>
              <UpdateProfileInfo />
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="secondary"
                  variant="contained"
                  disabled={!!isLoadingAuthContext}
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      }
      case "documents": {
        return (
          <>
            <DialogTitle id="form-dialog-title">Documents</DialogTitle>
            <DialogContent>
              <StudentDocuments />
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="secondary"
                  variant="contained"
                  disabled={!!isLoadingAuthContext}
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      }
      default: {
        return (
          <>
            <DialogTitle id="form-dialog-title">-</DialogTitle>
            <DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="secondary"
                  variant="contained"
                  disabled={!!isLoadingAuthContext}
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      }
    }
  };

  const getBackgroundImgSrc = () => {
    switch (location_id) {
      case "1": {
        return "northeast.jpg";
      }
      case "2": {
        return "orange-county.jpg";
      }
      case "3": {
        return "downtown.jpg";
      }
      case "4": {
        return "torrance.jpg";
      }
      default: {
        return "hollywood.jpg";
      }
    }
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"xl"}
      >
        {renderDialogOpen()}
      </Dialog>
      <Box
        paddingTop="3rem"
        paddingBottom="8rem"
        alignItems="center"
        display="flex"
        className={classes.wrapperBox}
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL
          }/location-background/${getBackgroundImgSrc()})`,
        }}
        minHeight="600px"
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          className={classes.overlayBox}
        />
        <Container
          display="flex"
          alignItems="center"
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                classes={{ root: classes.typographyRootH1 }}
              >
                {`${studentInfo.first_name} ${studentInfo.last_name}`}
              </Typography>
              <Box
                component="p"
                marginBottom="3rem"
                color={theme.palette.white.main}
                lineHeight="1.7"
                fontSize="1rem"
              >
                {locations[location_id]}
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  classes={{ root: classes.buttonRoot }}
                  startIcon={<Edit />}
                  onClick={() => handleOpenDialog("edit-profile")}
                >
                  Edit profile
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  classes={{ root: classes.buttonRoot }}
                  startIcon={<PrintIcon />}
                  onClick={handleClickPrintReportsMenu}
                >
                  Print Reports
                </Button>
                <Menu
                  id="print-reports-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClosePrintReportsMenu}
                >
                  <MenuItem onClick={() => handlePrintReport('attendance')}>
                    Print Attendance Report
                  </MenuItem>
                  <MenuItem onClick={() => handlePrintReport('grades')}>
                    Print Grades Report
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item>
                {unreadMessages.length > 0 ? (
                  <RouterLink
                    to={"/messages"}
                    style={{ textDecoration: "none" }}
                  >
                    <Badge
                      badgeContent={unreadMessages.length}
                      color="error"
                      classes={errorBadgeClasses}
                      children={
                        <Grid>
                          <Button
                            classes={{ root: classes.buttonRoot }}
                            variant="contained"
                            startIcon={<ChatIcon />}
                          >
                            Messages
                          </Button>
                        </Grid>
                      }
                    ></Badge>
                  </RouterLink>
                ) : (
                  <Grid>
                    <RouterLink to={"/messages"}>
                      <Button
                        classes={{ root: classes.buttonRoot }}
                        variant="contained"
                        startIcon={<ChatIcon />}
                      >
                        Messages
                      </Button>
                    </RouterLink>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  classes={{ root: classes.buttonRoot }}
                  startIcon={<DescriptionIcon />}
                  onClick={() => handleOpenDialog("documents")}
                >
                  Documents
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default UserHeader;
