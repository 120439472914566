import axios from "axios";

const filesHttpService = axios.create({
  baseURL: process.env.REACT_APP_FILE_SYSTEM_ADDRESS,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true
});

filesHttpService.interceptors.response.use(
  (res) => {
    return {
      data: res.data,
      status: res.status,
      message: res.data?.message ?? "",
    };
  },
  (err) => {
    if (err.response) {
      //Server responded with some error code
      return Promise.reject({
        data: err.response?.data ?? {},
        message:
          err.response.data?.messge ??
          "Request failed with status code " + (err.response?.status ?? 500),
        status: err.response.status ?? 500,
        error:
          err.response?.data?.error ??
          err.response.data?.errors ??
          err.response.errors ??
          true,
      });
    } else if (err.request) {
      // The request was made but no response was received
      return Promise.reject({
        message: "Could not receive server response: " + err.message,
        request: err.request,
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject({
        message: err.message,
      });
    }
  }
);

export default filesHttpService;
