import React, { useCallback } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { DatePicker } from "@material-ui/pickers";

const scheduleOptions = [
  {
    value: "morning",
    label: "Morning",
  },
  {
    value: "afternoon",
    label: "Afternoon",
  },
  {
    value: "evening",
    label: "Evening",
  },
];

const SuccessPayment = ({
  programSale,
  startDate,
  endDate,
  selectedPrice,
  handleBackButton,
}) => {
  const getProgramSaleOptions = useCallback(() => {
    if (Array.isArray(programSale)) {
      return programSale.map((element) => {
        return {
          value: element.program_id,
          label: element.program_name,
        };
      });
    }
    return [];
  }, [programSale]);

  return (
    <Grid container spacing={2} alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                name="program"
                id="program"
                label="Program"
                disabled
                value={
                  getProgramSaleOptions().find(
                    (option) => option.value === selectedPrice.program_id
                  ).label
                }
                placeholder="Program"
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                name="schedule"
                id="schedule"
                value={
                  scheduleOptions.find(
                    (option, index) => option.value === selectedPrice.schedule
                  ).label
                }
                disabled
                label="Schedule"
                placeholder="Schedule"
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <DatePicker
                InputLabelProps={{ shrink: true }}
                orientation="landscape"
                openTo="date"
                id="date_initial"
                disabled
                value={startDate}
                name="date_initial"
                label="Start date"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <DatePicker
                InputLabelProps={{ shrink: true }}
                orientation="landscape"
                openTo="date"
                id="date_final"
                name="date_final"
                value={endDate}
                disabled
                label="End date"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Number of Weeks"
                value={selectedPrice.number_of_weeks}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="filled"
              component={Box}
              fullWidth
              marginBottom="1rem!important"
            >
              <TextField
                disabled
                label="Price credit card"
                value={selectedPrice.price_credit_card}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="1.5rem"
          marginBottom="1.5rem"
        >
          Payment approved successfully
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="1.5rem"
          marginBottom="1.5rem"
        >
          <Button
            onClick={handleBackButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Back to prices
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SuccessPayment;
