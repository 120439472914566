import { FilesHttpService } from "services/files/Config";

export const sendFile = ({
  student_id,
  student_filenumber,
  location_id,
  file_type,
  file,
}) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("student_id", student_id);
  formData.append("student_filenumber", student_filenumber);
  formData.append("location_id", location_id);
  formData.append("file_type", file_type);

  return FilesHttpService.post("ws/student_files_upload.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
