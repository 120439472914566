import actions from "./actions";

const initialState = {
  classes: [],
  status: "idle", //'loading' | 'error' | 'success'
  meta: {
    current_page: 1,
    from: 1,
    to: 1,
    last_page: 1,
    links: [],
    path: "",
    per_page: 5,
    total: 1,
  },
  message: "", //Error message
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_CLASSES:
      return { ...state, status: "loading" };
    case actions.GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload?.data ?? [],
        status: "success",
        meta: {
          ...action.payload?.meta,
        },
      };
    case actions.GET_CLASSES_FAILURE:
      return {
        ...state,
        status: "error",
        message: action.payload?.message ?? "Error trying to load classes",
      };
    default:
      return state;
  }
}

export default Reducer;
