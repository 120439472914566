import React from "react";
import PropTypes from "prop-types";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Avatar, Tooltip } from "@material-ui/core";

function AvatarGroupWithTooltip({ avatars }) {
  return (
    <AvatarGroup style={{ display: "inline" }} max={8}>
      {avatars.map((value, index) => (
        <Tooltip key={index} title={value?.tooltipText ?? ""} arrow>
          <Avatar
            style={{
              color: value?.avatarTextColor ?? "",
              backgroundColor: value?.avatarBackgroundColor ?? "",
            }}
            src={value?.avatarSrc ?? ""}
          >
            {(value?.avatarFallbackText ?? "").toUpperCase()}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
}

AvatarGroupWithTooltip.propTypes = {
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      tooltipText: PropTypes.string,
      avatarTextColor: PropTypes.string,
      avatarFallbackText: PropTypes.string,
      avatarBackgroundColor: PropTypes.string,
      avatarSrc: PropTypes.string,
    })
  ).isRequired,
};

export default AvatarGroupWithTooltip;
