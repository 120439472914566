import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { Grid } from "@material-ui/core";
// core components
import componentStyles from "assets/theme/views/admin/payments.js";
import { useProgramPrices, useAttendanceDates } from "hooks";
import UserHeader from "components/Headers/UserHeader.js";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
} from "@material-ui/core";
import PaymentForm from "./components/PaymentForm";
import PaypalStep from "./components/PaypalStep";
import SuccessPayment from "./components/SuccessPayment";
import PaidList from "./components/PaidList";
const useStyles = makeStyles(componentStyles);
const paymentStyles = makeStyles((theme) => {
  return {
    zelle: {
      marginLeft: "70px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "35px",
      },
    },
    venmo: {
      marginLeft: "70px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "90px",
      },
    },
  };
});
const Payments = () => {
  const classes = useStyles();
  const paymentClasses = paymentStyles();
  const allPrices = useProgramPrices();
  const { validDates } = useAttendanceDates();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    program: "",
    schedule: "",
  });
  const [selectedPrice, setSelectedPrice] = useState({
    program_id: "",
    price_id: "",
    price_credit_card: "",
    price_cash: "",
    number_of_weeks: "",
    schedule: "",
  });

  const handleChange = (event) => {
    event.persist();

    setFormValues((formValuesCurrent) => ({
      ...formValuesCurrent,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (newDate) => {
    setEndDate(null);
    setStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
  };

  const handleBackButton = () => {
    setCurrentStep(0);
  };

  const handleContinueButton = () => {
    setCurrentStep(1);
  };

  const handleSuccess = () => {
    setCurrentStep(2);
  };

  useEffect(() => {
    if (canChooseEndDate() && DateTime.isDateTime(endDate)) {
      const numberOfWeeks = countNumberWeeks(
        startDate.startOf("day"),
        endDate.startOf("day")
      );
      var pricesOfSelectedProgram = allPrices.find(
        (price) => price.program_id === formValues.program
      );
      if (pricesOfSelectedProgram) {
        for (var programPrice of pricesOfSelectedProgram.prices) {
          if (
            programPrice.schedule === formValues.schedule &&
            programPrice.number_of_weeks === numberOfWeeks
          ) {
            setSelectedPrice({
              program_id: pricesOfSelectedProgram.program_id,
              price_id: programPrice.price_id,
              price_credit_card: programPrice.price_credit_card,
              price_cash: programPrice.price_cash,
              number_of_weeks: programPrice.number_of_weeks,
              schedule: programPrice.schedule,
            });
            return;
          }
        }
      }
    } else {
      setSelectedPrice({
        program_id: "",
        price_id: "",
        price_credit_card: "",
        price_cash: "",
        number_of_weeks: "",
        schedule: "",
      });
    }
  }, [startDate, endDate, formValues, allPrices]);

  const isValidDate = useCallback(
    (date) => {
      //Check if the given date is included on attendance dates and is not a holiday
      date = date.toSQLDate();
      return validDates.includes(date);
    },
    [validDates]
  );

  const getNextValidDate = useCallback(
    (date) => {
      //Get the next valid date after the given date
      for (let i = 0; i < validDates.length; i++) {
        let diff = DateTime.fromSQL(validDates[i]).diff(date, "days").days;
        if (diff > 0) {
          return DateTime.fromSQL(validDates[i]);
        }
      }
      return -1;
    },
    [validDates]
  );

  const canChooseStartDate = useCallback(() => {
    return formValues.program !== "" && formValues.schedule !== "";
  }, [formValues.program, formValues.schedule]);

  const isValidStartDate = useCallback(
    (date) => {
      //Check if a given date can be used as a start date
      if (!canChooseStartDate()) {
        return false;
      }
      if (date < DateTime.now()) {
        //Dates on the past can not be start dates
        return false;
      }
      const dayOfWeek = date.weekday;
      if (isValidDate(date) && dayOfWeek === 1) {
        return true;
      } else if (!isValidDate(date)) {
        return false;
      } else if (dayOfWeek !== 1 && !isValidDate(date.set({ weekday: 1 }))) {
        var nextValidDate = getNextValidDate(date.set({ weekday: 1 }));
        if (nextValidDate === -1) {
          //There are no valid dates after this monday
          return false;
        }

        if (nextValidDate.startOf("day") === date.startOf("day")) {
          return true;
        }
      }
      return false;
    },
    [isValidDate, canChooseStartDate, getNextValidDate]
  );

  const canChooseEndDate = useCallback(() => {
    return canChooseStartDate() && isValidStartDate(startDate);
  }, [canChooseStartDate, startDate, isValidStartDate]);

  const isValidEndDateWithSelectedPrice = (date) => {
    if (!canChooseEndDate()) {
      return false;
    }
    if (!isValidEndDate(date)) {
      return false;
    }
    const numberOfWeeks = countNumberWeeks(
      startDate.startOf("day"),
      date.startOf("day")
    );
    var pricesOfSelectedProgram = allPrices.find(
      (price) => price.program_id === formValues.program
    );
    if (pricesOfSelectedProgram) {
      for (var programPrice of pricesOfSelectedProgram.prices) {
        if (
          programPrice.schedule === formValues.schedule &&
          programPrice.number_of_weeks === numberOfWeeks
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const isValidEndDate = (date) => {
    //Check if given date is a valid end date considering the selected schedule
    if (!canChooseEndDate()) {
      return false;
    }
    if (date < DateTime.now()) {
      return false;
    }
    if (!isValidDate(date)) return false;
    if (formValues.schedule === "afternoon") {
      return date.weekday === 4;
    }
    return date.weekday === 5;
  };

  const countNumberWeeks = (initialDate, finalDate) => {
    const difference = finalDate.diff(initialDate, "days").days;
    var count = 0;
    var initialDateTmp = initialDate;
    for (var i = 1; i <= difference; i++) {
      initialDateTmp = initialDateTmp.plus({ days: 1 });
      if (isValidEndDate(initialDateTmp)) {
        count++;
      }
    }
    return count;
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container maxWidth={false} component={Box} marginTop="-6rem">
        <Card
          classes={{
            root: classes.cardRoot + " " + classes.cardRootSecondary,
          }}
        >
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
              content: classes.cardHeaderContent,
            }}
            title="Prices"
            subheader="Check the prices for our programs"
          ></CardHeader>
          <CardContent>
            {currentStep === 0 && (
              <PaymentForm
                programSale={allPrices}
                isValidStartDate={isValidStartDate}
                isValidEndDate={isValidEndDateWithSelectedPrice}
                formValues={formValues}
                setStartDate={handleStartDateChange}
                setEndDate={handleEndDateChange}
                startDate={startDate}
                endDate={endDate}
                handleChange={handleChange}
                selectedPrice={selectedPrice}
                handleContinueButton={handleContinueButton}
                canChooseEndDate={canChooseEndDate()}
              />
            )}
            {currentStep === 1 && (
              <PaypalStep
                programSale={allPrices}
                startDate={startDate}
                endDate={endDate}
                selectedPrice={selectedPrice}
                handleBackButton={handleBackButton}
                handleSuccess={handleSuccess}
              />
            )}
            {currentStep === 2 && (
              <SuccessPayment
                programSale={allPrices}
                startDate={startDate}
                endDate={endDate}
                selectedPrice={selectedPrice}
                handleBackButton={handleBackButton}
              />
            )}
          </CardContent>
          <Grid container spacing={2}>
            <Grid item alignItems="center" xs={12} sm={6}>
              <img
                className={paymentClasses.zelle}
                width="80%"
                alt="zelle-qrcode"
                src={require("../../assets/img/payments/zelle-qr.png").default}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                className={paymentClasses.venmo}
                width="45%"
                alt="venmo-qrcode"
                src={
                  require("../../assets/img/payments/paypal-qrcode.png").default
                }
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      <PaidList />
    </>
  );
};

export default Payments;
