import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// @material-ui/lab components
import Backdrop from "@material-ui/core/Backdrop";
import Pagination from "@material-ui/lab/Pagination";

import componentStyles from "assets/theme/views/admin/tables.js";
import { CircularProgress, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/AssignedClass/actions";
import AssignedClassGrades from "components/Shared/AssignedClassGrades";

const useStyles = makeStyles(componentStyles);

const GradesTable = () => {
  const classes = useStyles();
  const {
    classes: assignedClasses,
    status,
    meta,
    message,
  } = useSelector((state) => state.assignedClassReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const getClasses = (page = 1) => {
    dispatch({
      type: actions.GET_CLASSES,
      payload: {
        page,
        load: ["attendance", "session", "level", "programClass", "grades"],
      },
    });
  };

  useEffect(() => {
    if (status === "loading") {
      return;
    }
    if (status === "idle") {
      return getClasses(currentPage);
    } else if (status === "success" && currentPage !== meta.current_page) {
      return getClasses(currentPage);
    }
  }, [currentPage, status, meta]);

  const theme = useTheme();
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  return (
    <div className={classes.parentOfBackdrop}>
      <Backdrop
        className={classes.backdrop}
        open={status === "loading" || status === "idle"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {assignedClasses.map((assignedClass, index) => (
        <Box className={classes.marginTop} key={index}>
          <AssignedClassGrades
            assignedClass={assignedClass}
            color={theme.palette.error.main}
          />
        </Box>
      ))}
      <Grid
        container
        classes={{ root: classes.cardActionsRoot }}
        justify="flex-end"
      >
        <Pagination
          count={meta.last_page}
          color="primary"
          variant="outlined"
          page={currentPage}
          onChange={handlePageChange}
        />
      </Grid>
    </div>
  );
};

export default GradesTable;
