import React from "react";
import PropTypes from "prop-types";
import ChatComponent from "components/Shared/ChatComponent";
import UserHeader from "components/Headers/UserHeader";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import componentStyles from "assets/theme/views/admin/profile.js";

const useStyles = makeStyles(componentStyles);

function MessageBoard() {
  const classes = useStyles();
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <ChatComponent />
      </Container>
    </>
  );
}

// MessageBoard.propTypes = {
//   props: PropTypes,
// };

export default MessageBoard;
