import React from "react";
import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";
import CalendarTodayRounded from "@material-ui/icons/CalendarTodayRounded";
import Payment from "@material-ui/icons/Payment";
import Chat from "@material-ui/icons/Chat";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { MenuBook, VpnLock } from "@material-ui/icons";

const DashboardViewComponent = React.lazy(() => import("../views/Dashboard"));
const LoginViewComponent = React.lazy(() => import("views/Login"));
const ProfileViewComponent = React.lazy(() => import("views/Profile"));
const CalendarViewComponent = React.lazy(() => import("views/Calendar"));
const PaymentsViewComponent = React.lazy(() => import("views/Payments"));
const OrientationComponent = React.lazy(() => import("views/OrientationVideo"));
const MessageBoardComponent = React.lazy(() => import("views/MessageBoard"));
const orientationComponent = React.lazy(() => import("views/Orientation"));

var routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: Tv,
    iconColor: "Primary",
    component: DashboardViewComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
  {
    path: "profile",
    name: "Profile",
    icon: Person,
    iconColor: "WarningLight",
    component: ProfileViewComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
  {
    path: "calendar",
    name: "Calendar",
    icon: CalendarTodayRounded,
    iconColor: "Secondary",
    component: CalendarViewComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
  {
    path: "payments",
    name: "Payments",
    icon: Payment,
    iconColor: "Success",
    component: PaymentsViewComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
  {
    path: "messages",
    name: "Message Board",
    icon: Chat,
    iconColor: "Secondary",
    component: MessageBoardComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
  {
    path: "login",
    name: "Login",
    icon: VpnLock,
    iconColor: "Info",
    component: LoginViewComponent,
    layout: "minimal",
    showOnSideBar: false,
    isPrivate: false,
  },
  {
    path: "orientation",
    name: "Orientation",
    icon: AssignmentTurnedInIcon,
    iconColor: "Secondary",
    component: orientationComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
  {
    name: "Ebook",
    icon: MenuBook,
    iconColor: "Secondary",
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
    isLink: true,
  },
];

var orientationRoute = [
  {
    path: "orientation",
    name: "Orientation",
    icon: AssignmentTurnedInIcon,
    iconColor: "Secondary",
    component: OrientationComponent,
    layout: "main",
    showOnSideBar: true,
    isPrivate: true,
  },
];

export { routes, orientationRoute };
