import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./helpers";
import ProtectedRoutes from "./ProtectedRoutes";

import { Login as LoginPage } from "views";
import { FactSheet as FactSheet } from "views";
import { MinimalLayout } from "layouts";
import { Suspense } from "react";
import "animate.css";
import { Backdrop, makeStyles } from "@material-ui/core";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import SchoolFactSheet from "views/SchoolFactSheet";
import SignedFactSheetPage from "views/SignedFactSheetPage";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background:
      "linear-gradient(87deg," + theme.palette.info.main + ",#be0061)",
  },
}));

export function AppRoutes({ isAuthenticated, isLoading }) {
  const classes = useStyles();
  return (
    <Router>
      <Suspense
        fallback={
          <Backdrop className={classes.backdrop} open={true}>
            <img
              alt="logo-lsi"
              className="animate__animated animate__pulse animate__infinite"
              src={require("../assets/img/brand/argon-react.png").default}
            />
          </Backdrop>
        }
      >
        <Switch>
          <PublicRoute path="/" isAuthenticated={isAuthenticated} exact>
            <MinimalLayout>
              <LoginPage />
            </MinimalLayout>
          </PublicRoute>
          <PublicRoute path="/login" isAuthenticated={isAuthenticated} exact>
            <MinimalLayout>
              <LoginPage />
            </MinimalLayout>
          </PublicRoute>
          <Route path="/fact-sheet/:token" isAuthenticated={isAuthenticated} exact>
              <FactSheet/>
          </Route>
          <Route path="/fact-sheet-signed" isAuthenticated={isAuthenticated} exact>
              <SignedFactSheetPage/>
          </Route>
          <Route path="/school-fact-sheet/:location/:factSheetId/:schoolOfficialId/:hmac" isAuthenticated={isAuthenticated} exact>
              <SchoolFactSheet />
          </Route>
          <PrivateRoute
            path="/"
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          >
            <ProtectedRoutes />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
}
