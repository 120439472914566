// Here we include the components which need to be accesses after successful login.
import { Route, Switch } from "react-router-dom";
import { mainLayoutRoutes } from "./helpers";
import { MainLayout } from "layouts";
import { useContext } from "react";
import { AuthContext } from "context";
import Orientation from "views/Orientation";

function ProtectedRoutes() {
  const { studentInfo } = useContext(AuthContext);
  return (
    <MainLayout>
      {studentInfo.read_orientation !== 1 ? (
        <Switch>
          <Route path={"/orientation"} exact component={Orientation} />
          <Route path={"*"} component={Orientation}></Route>
        </Switch>
      ) : (
        <Switch>
          {mainLayoutRoutes.map(
            ({ component: Component, path, exact }, index) => (
              <Route
                path={`/${path}`}
                key={index}
                exact={exact}
                component={Component}
              />
            )
          )}
        </Switch>
      )}
    </MainLayout>
  );
}

export default ProtectedRoutes;
