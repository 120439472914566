import axios from "axios";
import {
  getUserEmail,
  getUserAuthHash,
} from "services/api/Repos/Student/student";

export async function sendRequest() {
  try {
    const emailResponse = await getUserEmail();
    const hashResponse = await getUserAuthHash();
    const email = emailResponse.data.email;
    const hash = hashResponse.data.hash;

    const validationUrl = `${process.env.REACT_APP_MYLSI_URL}/lsi-validate`;
    const data = {
      email: email,
      hash: hash,
    };

    return axios
      .post(validationUrl, data)
      .then((response) => {
        let newTab = window.open();
        newTab.location.href = response.data.auth;
      })
      .catch(({ response }) => {
        console.log(response);
      });
  } catch (error) {
    console.log(error);
  }
}
